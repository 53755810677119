import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import { ENVIRONMENT } from 'src/environments/environment.development';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-external-url-support',
  templateUrl: './external-url-support.component.html',
  styleUrls: ['./external-url-support.component.css']
})
export class ExternalUrlSupportComponent implements  OnInit{

  private href: string = "";
  private userId:any;

  constructor(private router: Router, private cookieService: CookieServiceWrapper, private authService:AuthService) {}

  ngOnInit(): void {
    this.href = this.router.url;

    if(!this.cookieService.get('redirect')){
      this.cookieService.set('redirect',this.href, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
    }
    // this.cookieService.set('external',this.href, 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');

    this.userId = this.authService.getUserId();
    if(!this.userId){
      return;
    }

    if (this.router.url.includes('savedSearch=')) {

      // alert("SAVEDSEARCH");
      var searchId = this.href.split('savedSearch=')?.[1];
      var redirectTo = "/share/searches/"+searchId;
      this.router.navigateByUrl(redirectTo,{
        state:{}
      });

    }else if(this.router.url.includes('collection=')){

      // alert("COLLECTION");
      var collectionId = this.href.split('collection=')?.[1];
      var redirectTo = "/share/collections/"+collectionId;
      this.router.navigateByUrl(redirectTo,{
        state:{}
      });

    }else if(this.router.url.includes('q=')){

      // alert("NORMAL Q");
      if(this.router.url.includes('t=SP') && this.router.url.includes('contentsourceid')){
        var re = /contentsourceid/gi;
        var newURL = (this.router.url).replace(re, "spouniqueid");
        var query = newURL.split('#')?.[1];
        var redirectTo = "/#"+query;
        this.router.navigateByUrl(redirectTo,{
          state:{}
        });
      }else{
        var query = this.href.split('#')?.[1];
        var redirectTo = "/#"+query;
        this.router.navigateByUrl(redirectTo,{
          state:{}
        });
      }

    }else if(this.router.url.includes('notification=')){

      // alert("notification");
      var redirectTo = "/?notifications=true";
      this.router.navigateByUrl(redirectTo,{
        state:{}
      });

    }

    // console.log(this.router.url);
  }

}
