
import { Component, OnInit, Renderer2 } from '@angular/core';
import { OneDriveRegistrationService } from '../services/one-drive-registration.service';
import {Router} from '@angular/router';
import { CONFIG } from 'src/environments/environment.development';
import { OnedriveService } from '../services/onedrive.service';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-one-drive-registeration-multiple',
  templateUrl: './one-drive-registeration-multiple.component.html',
  styleUrls: ['./one-drive-registeration-multiple.component.css']
})
export class OneDriveRegisterationMultipleComponent {
  document:any;
  failedDocumentListEnable= false;
  title1:any="";
  infoMSG:any = CONFIG.ONEDRIVE.INFO_MESSAGE;
  

  title:any= CONFIG.ONEDRIVE.REGISTRATION_TITLE;
   actionsFlag:number = 1;
   failedListDocs:any =[];
  
  closeTab(){
    this._router.navigateByUrl("/");
  this.renderer.setProperty(window,'closed',true);
  window.close();
  }

  async deleteEntryFromDB(){
  //   let payload = {
  //     id:CONFIG.TEMP.USERID,
  //      code:"",
  //      source:CONFIG.TEMP.SOURCE,
  //      docId:CONFIG.TEMP.DOCID,
  //      isfailed : true
  // };

     let payload = {
      id:localStorage.getItem("userId"),
       code:"",
       source:localStorage.getItem("source"),
       docId:localStorage.getItem("docId"),
       isfailed : true
  };


  let response = (await this.oneDriveRegisterService.deleteEntryFromDatabase(payload)).toPromise();

  // console.log("response", response);
  }

 async register(){
  // console.log("registration started!");

  let requestPayload = {
    tenantId:CONFIG.ONEDRIVE.TENANT_ID,
    client_id:CONFIG.ONEDRIVE.CLIENT_ID,
    redirectUri:CONFIG.ONEDRIVE.REDIRECT_URL_MULTIPLE
   }
    await this.oneDriveRegisterService.getAuthToken(requestPayload);
    localStorage.setItem('authCode', '1');
 }

  constructor(private oneDriveRegisterService:OneDriveRegistrationService, private renderer:Renderer2, private _router:Router, private oneDrive:OnedriveService, private cookieService:CookieServiceWrapper,private authServic:AuthService, private onedriveService:OnedriveService){
           
  }
  sleep(ms:number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
   
  async ngOnInit() {

    //  localStorage.setItem("userId", "4604");
    //  localStorage.setItem("docId", "3808");
    //  localStorage.setItem("source", "VVMC");

    //var selectedDocs =  this.cookieService.get("selectedDocs");
    var selectedDocs:any = localStorage.getItem("selectedDocs");
    // console.log("selectdDocs",JSON.parse(selectedDocs));
  
    
   try{
      var authCode =  localStorage.getItem("authCode");
      if(authCode == null){
    
    }
    else{
      this.title= CONFIG.ONEDRIVE.REGISTRATION_PROGRESS_MULTI_MESSAGE;
      this.actionsFlag = 3;
      // console.log("authCode", authCode);
      var currentLocation =window.location.href;
   
      if(currentLocation.includes("code")){
          var code = currentLocation.split("?")?.[1].split("&")?.[0].split("=")?.[1];
       // console.log("code", code);
        // let requestPayload = {
        //    id:CONFIG.TEMP.USERID,
        //    code:code,
        //    source:CONFIG.TEMP.SOURCE,
        //    docId:CONFIG.TEMP.DOCID
        // }

         let requestPayload = {
           id:this.authServic.getUserId(),
           code:code,
          //  source:localStorage.getItem("source"),
          //  docId:localStorage.getItem("docId"),
           isMultiple: true
        }
        // console.log("request payload", requestPayload);
        var source = localStorage.getItem("source");
        var response:any =  await  (await this.oneDriveRegisterService.registerUserToOneDrive(requestPayload)).toPromise();
        // console.log("registration response", response);
          var status:boolean = false;
         if(response.statusCode == 200){

          //this.document = JSON.parse(this.cookieService.get("selectedDocs"));
          let docs:any = localStorage.getItem("selectedDocs");
          this.document= JSON.parse(docs);
    // console.log('Documents', this.document)
    var uniqueId = ''
    var flag = true;
    let source: any;
    var userId = this.authServic.getUserId();
    var payloadItems: any = []
    for (let i = 0; i < this.document.length; i++) {
      payloadItems.push({
        docId: this.document[i].docId,
        userId: userId,
        source: this.document[i].source
      })
    }
    // console.log('this.payloadItems', payloadItems);
    // console.log('this.payloadItems length', payloadItems.length);
    for await (const payload of payloadItems) {
      flag = true
      uniqueId = ''
      await this.sleep(5000);
      do {
        if (uniqueId.length != 0) {
           payload['uniqueId'] = uniqueId
        }
        await this.sleep(1000);
        // console.log('payload', payload);
        var oneDriveMultiplecontent: any
        if (payload.source == 'VVMC') {
          oneDriveMultiplecontent = await (
            await this.onedriveService.downloadVVMCDocumentToOneDrive(payload)
          ).toPromise()
        }
        if (payload.source == 'VVQD') {
          oneDriveMultiplecontent = await (
            await this.onedriveService.downloadVVQDocumentToOneDrive(payload)
          ).toPromise()
        }
        // console.log('oneDrive download Response', oneDriveMultiplecontent)
        uniqueId = oneDriveMultiplecontent.uniqueId

        if (oneDriveMultiplecontent.msg == 'Upload Completed') {
          flag = false
          // status = true
        }

        if (oneDriveMultiplecontent.msg == 'Upload Failed') {
          flag = false;

          let item = this.document.find(doc => doc.docId === payload.docId);

          let failedDoc = {
            id:payload.docId,
            name:item.name,
          }
          this.failedListDocs.push(failedDoc);
          // console.log("this.failedListDocs", this.failedListDocs);
        }
      } while (flag);
    }

         }
         else{
          localStorage.clear();
          this.actionsFlag = 2;
            this.title= CONFIG.ONEDRIVE.REGISTRATION_FAILURE_MESSAGE;
            return;
            
         }

         if(this.failedListDocs.length == 0){
          this.actionsFlag = 2
          this.title= CONFIG.ONEDRIVE.REGISTRATION_SUCCESS_MULTI_MESSAGE;
           localStorage.removeItem("authCode");
           localStorage.clear();
         }
         else{

          //   if(this.failedListDocs.length ==  this.document.length){
          //     this.actionsFlag = 2;
          // this.title= CONFIG.ONEDRIVE.REGISTRATION_FAILURE_MULTI_MESSAGE;
          // this.failedDocumentListEnable = true;
          //  localStorage.removeItem("authCode");
          //  localStorage.clear();
          //   }
          //   else{
          this.actionsFlag = 2;
          // this.title= CONFIG.ONEDRIVE.REGISTRATION_SUCCESS__PARTIAL_MESSAGE;
          this.title= CONFIG.ONEDRIVE.REGISTRATION_SUCCESS__PARTIAL_MESSAGE_1;
          this.title1= CONFIG.ONEDRIVE.REGISTRATION_SUCCESS__PARTIAL_MESSAGE_2;
          this.failedDocumentListEnable = true;
           localStorage.removeItem("authCode");
           localStorage.clear();
           // }
         }


    }
  }
}
catch(error){
        // console.log("error", error);
        localStorage.clear();
}
}
}
