import { Component,Input, NgZone } from '@angular/core';
import {EngineService} from '../engine.service';
import { Quickview, buildQuickview,Result, QuickviewOptions,QuickviewState, QuickviewProps} from '@coveo/headless';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Customeventlogging } from '../customevent-logging.service';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';


@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.css']
})
export class QuickViewComponent {
  @Input() imageUrl!: string;
  @Input() result!:Result;

  private quickview!: Quickview;
  // public content!: string;
  public safeUrl: SafeResourceUrl | undefined;

  isModalOpen = false;

  public constructor(private engineService: EngineService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private cookieService : CookieServiceWrapper,
    private eventLoggingService:Customeventlogging) {
    // this.initializeController();
  }

  public ngOnInit(): void {
    this.initializeController();
  }


  private async initializeController() {
    const engine = await this.engineService.getEngibeObj();
    // this.state = this.quickview.state;
    const options: QuickviewOptions={
      result: this.result,
      onlyContentURL:true,
      // maximumPreviewSize:10
    }

    const props: QuickviewProps = {
      options : options,
    }

    this.quickview = buildQuickview(engine,props);

    //  this.quickview.subscribe(() => {
    //    this.fetchQuickViewContent();
    //  });
  }

  // public get content(){
  //   return this.quickview.state.content;
  // }

  openQuickView() {

    this.ngZone.run(() => {
      this.initializeController();
    // console.log(JSON.stringify(this.result));
    this.quickview.fetchResultContent();
      this.isModalOpen = true;
    });

    // this.initializeController();
    // // console.log(JSON.stringify(this.result));
    // this.quickview.fetchResultContent();
    // this.fetchQuickViewContent();
    
  }



  fetchQuickViewContent(){
    // this.quickview.fetchResultContent();
    // this.content = this.quickview.state.contentURL || '';
    // console.log(this.quickview.state);
    this.openModal();
  }

  public get content(){
      return this.quickview.state.contentURL || '';
  }

  openModal() {
    // this.quickview.fetchResultContent();
    // this.content = this.quickview.state.contentURL || '';
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }


  // custom Events
   
  customEventsQuickView()
  {
      // console.log("TitleName", this.result);

    //  this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click', Title : this.result['Title'],'Source' : this.result['raw']['source']},'Quick-View');
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click', Title : this.result['Title'],'Source' : this.result['raw']['source']},'Preview');
  
  }


}
