
import { Component, OnInit, Renderer2 } from '@angular/core';
import { OneDriveRegistrationService } from '../services/one-drive-registration.service';
import {Router} from '@angular/router';
import { CONFIG, ENVIRONMENT } from 'src/environments/environment.development';
import { OnedriveService } from '../services/onedrive.service';
@Component({
  selector: 'app-one-drive-registration',
  templateUrl: './one-drive-registration.component.html',
  styleUrls: ['./one-drive-registration.component.css']
})
export class OneDriveRegistrationComponent implements OnInit {

  title:any= CONFIG.ONEDRIVE.REGISTRATION_TITLE;
  infoMSG:any = CONFIG.ONEDRIVE.INFO_MESSAGE;
   actionsFlag:number = 1;
   contactMessage:any = "";
  
  closeTab(){
    this._router.navigateByUrl("/");
  this.renderer.setProperty(window,'closed',true);
  window.close();
  }

  async deleteEntryFromDB(){
  //   let payload = {
  //     id:CONFIG.TEMP.USERID,
  //      code:"",
  //      source:CONFIG.TEMP.SOURCE,
  //      docId:CONFIG.TEMP.DOCID,
  //      isfailed : true
  // };

     let payload = {
      id:localStorage.getItem("userId"),
       code:"",
       source:localStorage.getItem("source"),
       docId:localStorage.getItem("docId"),
       isfailed : true
  };


  let response = (await this.oneDriveRegisterService.deleteEntryFromDatabase(payload)).toPromise();

  // console.log("response", response);
  }

 async register(){
  // console.log("registration started!");

  let requestPayload = {
    tenantId:CONFIG.ONEDRIVE.TENANT_ID,
    client_id:CONFIG.ONEDRIVE.CLIENT_ID,
    redirectUri:CONFIG.ONEDRIVE.REDIRECT_URL
   }
    await this.oneDriveRegisterService.getAuthToken(requestPayload);
    localStorage.setItem('authCode', '1');
 }

  constructor(private oneDriveRegisterService:OneDriveRegistrationService, private renderer:Renderer2, private _router:Router, private oneDrive:OnedriveService){
           
  }
  sleep(ms:number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
   
  async ngOnInit() {

    //  localStorage.setItem("userId", "4604");
    //  localStorage.setItem("docId", "3808");
    //  localStorage.setItem("source", "VVMC");
   try{
      var authCode =  localStorage.getItem("authCode");
      if(authCode == null){
    
    }
    else{
      this.title= CONFIG.ONEDRIVE.REGISTRATION_PROGRESS_MESSAGE;
      this.actionsFlag = 3;
      // console.log("authCode", authCode);
      var currentLocation =window.location.href;
   
      if(currentLocation.includes("code")){
          var code = currentLocation.split("?")?.[1].split("&")?.[0].split("=")?.[1];
       // console.log("code", code);
        // let requestPayload = {
        //    id:CONFIG.TEMP.USERID,
        //    code:code,
        //    source:CONFIG.TEMP.SOURCE,
        //    docId:CONFIG.TEMP.DOCID
        // }

         let requestPayload = {
           id:localStorage.getItem("userId"),
           code:code,
           source:localStorage.getItem("source"),
           docId:localStorage.getItem("docId")
        }
        var source = localStorage.getItem("source");
        var response:any =  await  (await this.oneDriveRegisterService.registerUserToOneDrive(requestPayload)).toPromise();
        // console.log("registration response", response);
          var status:boolean = false;
         if(response.statusCode == 200){
          var uniqueId = "";
          var flag = true;
     
          // var payload:any = {
          //  docId:CONFIG.TEMP.DOCID,
          //  userId:CONFIG.TEMP.USERID,
          // }
          var payload:any = {
            docId:localStorage.getItem("docId"),
            userId:localStorage.getItem("userId"),
           }
         do{
                if(uniqueId.length != 0){
                        payload['uniqueId'] = uniqueId;
 
                        await this.sleep(5000);
                } 
                // console.log("payload", payload);
                 var content:any;
                if(source == "VVMC"){
                  content=  await (await this.oneDrive.downloadVVMCDocumentToOneDrive(payload)).toPromise();
                    }
                    if(source == "VVQD"){
                      content=  await (await this.oneDrive.downloadVVQDocumentToOneDrive(payload)).toPromise();
                    }
                  // let content:any =  await (await this.oneDriveRegisterService.downloadVVMCDocumentToOneDrive(payload)).toPromise();
               // console.log("oneDrive download Response", content);
               uniqueId = content.uniqueId;
        
       if(content.msg == "Upload Completed"){
             flag= false;
             status = true;
       }
 
       if(content.msg == "Upload Failed"){
         flag= false;

       }
 
         }while(flag);
         
           if(status){
            this.actionsFlag = 2
           this.title= CONFIG.ONEDRIVE.REGISTRATION_SUCCESS_MESSAGE;
            localStorage.removeItem("authCode");
            localStorage.clear();
           }
           else{
           await this.deleteEntryFromDB();
            this.actionsFlag = 2
            this.title= CONFIG.ONEDRIVE.REGISTRATION_FAILURE_MESSAGE;
            this.contactMessage= CONFIG.ONEDRIVE.CONTACT_MESSAGE;
            localStorage.removeItem("authCode");
            localStorage.clear();
           }

         }
         else{
          localStorage.clear();
          this.actionsFlag = 2
            this.title= CONFIG.ONEDRIVE.REGISTRATION_FAILURE_MESSAGE;
            this.contactMessage= CONFIG.ONEDRIVE.CONTACT_MESSAGE;
            localStorage.removeItem("authCode");
            localStorage.clear();
         }
    }
  }
}
catch(error){
        // console.log("error", error);
        localStorage.clear();
}
}




  
  

}
