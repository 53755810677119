import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from 'src/environments/environment.development';
import { EngineService } from './engine.service';
import { AuthService } from './auth.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class SavedsearchService {

  engine:any;
  queryPayLoad = {
    locale: 'en-US',
    searchHub: 'AdvanceSearch',
    tab: '',
    cq: '',
    aq: '',
    q: '',
    groupBy: [
      {
        field: '@contentapprovaldate',
        sortCriteria: 'AlphaAscending'
      }
    ]
  }


  constructor(private httpClient:HttpClient,private engineService: EngineService,private auth: AuthService, private sharedService: SharedService) {
      this.engine = engineService.getEngibeObj();
   }






   saveSearchTest(patternName:any) {
    var coveoFacetFilters = ''
    const query = this.engine.state.query
    const facetState = this.engine.state.search.response.facets
    coveoFacetFilters = this.engine.state.search.response.facets
    // console.log('Query Payload:', JSON.stringify(facetState))


  }






  saveSearch (patternObj:any) {
    var coveoFacetFilters = ''
    const query = this.engine.state.query
    const facetState = this.engine.state.search.response.facets
    coveoFacetFilters = this.engine.state.search.response.facets
    // console.log('Query Payload:', JSON.stringify(facetState))

    var queryFacets = ''
    var dateQueryFacet = '';
    var openBracetAdded = false
    var upperLoopReached = false
    // for(let [i,facet] of facetState){
    if (facetState.length > 0) {
      for (let i = 0; i < facetState.length; i++) {
        // // console.log("facet-->"+JSON.stringify(facet));
        if (queryFacets != '') {
          upperLoopReached = true
        }
        var facetValuesList = facetState[i].values
        if (facetValuesList.length > 0) {
          // for(var [j,facetValueObj] of facetState[i].values){
          var facetedVlues = facetState[i].values
          for (var j = 0; j < facetedVlues.length; j++) {
            if (facetedVlues[j].state == 'selected') {
              if (upperLoopReached) {
                queryFacets = queryFacets + ') AND '
              }
              if (queryFacets != '' && !upperLoopReached) {
                queryFacets = queryFacets + ' OR '
              }
              upperLoopReached = false;
              if(facetState[i].facetId =="contentapprovaldate_input"){

                var facetId = '@' + facetState[i].facetId
              // if (j == 0) {
                facetId = facetId
                // openBracetAdded = true
              // }
              dateQueryFacet = dateQueryFacet + facetId +" "+ `>=` +" "+ facetedVlues[j].start;
              dateQueryFacet = dateQueryFacet +" "+" AND "+" ";
              dateQueryFacet = dateQueryFacet + facetId +" "+ `>=` +" "+ facetedVlues[j].end;
              }else{

                var facetId = '@' + facetState[i].facetId
              if (j == 0) {
                facetId = '(' + facetId
                openBracetAdded = true
              }
              queryFacets =
                queryFacets + facetId + `==\"` + facetedVlues[j].value + `\"`

              }
              

              // console.log(
                'select facet.facetId ==> ' + JSON.stringify(facetedVlues[j])
              
             // console.log("select facet.facetId ==> "+facetValueObj['value']);
            }
          }
        }

        // else{
        //   facetValuesList = facet['currentValues'];
        //   if(facetValuesList.length>0){

        //   }
        // }
      }
    }

    if (openBracetAdded) {
      queryFacets = queryFacets + ')'
      openBracetAdded = false
    }

    this.queryPayLoad.cq = queryFacets
    this.queryPayLoad.q = query.q
    this.queryPayLoad.aq = dateQueryFacet;

    // console.log('queryPayLoad---->' + JSON.stringify(this.queryPayLoad));
    if(this.queryPayLoad.cq){
      coveoFacetFilters = JSON.stringify(coveoFacetFilters);
      coveoFacetFilters = coveoFacetFilters.replaceAll(/'/g,"");
    }else{
      coveoFacetFilters = "";
    }
    
    var userId = this.auth.getUserId();

    if(!patternObj.description){
             patternObj.description = "";
    }
    let payload = {
      userId: userId,
      userISID: '',
      userEmail: '',
      favouriteSearchpattern: JSON.stringify(this.queryPayLoad),
      patternName: patternObj.pattern,
      description:patternObj.description,
      coveoFacet: coveoFacetFilters,
      version: 4,
      source: 'ALL'
    }

    // console.log('payload check---->' + JSON.stringify(payload));
    //return;
    let searchesVar = {
      'feature':'savedSearch',
      'data':"",
      'description':patternObj.description,
      "name":patternObj.pattern
    }
    this.saveSearchToDB(payload).subscribe((Searchres)=>{
      searchesVar.data = Searchres;
      this.sharedService.sendSavedSearch(searchesVar);
      // alert("Searchres-->"+JSON.stringify(Searchres));
      // searchesVar = Searchres;
      // return Searchres;
    })

    // return searchesVar;

  }



  getAllPatterns(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.SAVEDSEARCH.GET_ALL_PATTERNS, requestPayload);
  }

  saveSearchToDB(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.SAVEDSEARCH.SAVE_SEARCH, requestPayload);
  }

  renameSearchToDB(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.SAVEDSEARCH.RENAME_DELETE_PATTERN, requestPayload);
  }
  
  subscribeSearch(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.SAVEDSEARCH.SUBSCRIBE_COLLECTIONS, requestPayload);
  }
  unsubscribeSearch(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.SAVEDSEARCH.SUBSCRIBE_COLLECTIONS, requestPayload);
  }
}
