import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class OneDriveRegistrationService {

  constructor(private httpClient:HttpClient) { }

  async getAuthToken(requestPayload:any){

    window.location.href=`https://login.microsoftonline.com/${requestPayload.tenantId}/oauth2/V2.0/authorize?response_type=code&client_id=${requestPayload.client_id}&redirect_uri=${requestPayload.redirectUri}&scope=offline_access+openid+profile+User.Read`;

  }
  
  async registerUserToOneDrive(requestPayload:any){

    return await  this.httpClient.post(ENVIRONMENT.ONEDRIVE.REGISTER_USER,requestPayload);

  }

  async downloadVVMCDocumentToOneDrive(requestPayload:any){
    return await this.httpClient.post(ENVIRONMENT.ONEDRIVE.ADD_TO_ONEDRIVE_VVMC, requestPayload);
  }

  async deleteEntryFromDatabase(requestPayload:any){
    return await this.httpClient.post(ENVIRONMENT.ONEDRIVE.REGISTER_USER,requestPayload);
  }

}
