import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieServiceWrapper } from './cookie-service-wrapper.service';
import { CONFIG } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ExportToExcelService {

  constructor(private httpClient:HttpClient,private cookieservice : CookieServiceWrapper) { 

  }

   getTotalSize(requestPayload:any){

    // var organizationId= 'merckholdingsllcnonproduction1xzti28zp';
    //  var accessToken= 'xx24b1e8e0-1dad-4359-a72a-44418b8bbd6c';

    var organizationId= this.cookieservice.get('oid');
    var accessToken= this.cookieservice.get('st');
   
    
  
    // var url = `https://merckholdingsllcnonproduction1xzti28zp.org.coveo.com/rest/search/v2??organizationId=${organizationId}`;
    var url = CONFIG.EXPORT_TO_EXCEL.API_URL;

   return this.httpClient.post(requestPayload,{'Authorization': `Bearer ${accessToken}`});

   }

}
