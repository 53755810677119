import { Component,Input,OnInit } from '@angular/core';
import { NotificationService } from '../../notification.service';
import { EngineService } from 'src/app/engine.service';
import { loadQueryActions, loadSearchActions, loadSearchAnalyticsActions } from '@coveo/headless';
import { CONFIG } from 'src/environments/environment.development';
import { Router } from '@angular/router';
import { Customeventlogging } from 'src/app/customevent-logging.service';
import { SharedService } from 'src/app/shared.service';
import { AuthService } from 'src/app/auth.service';
import { ENVIRONMENT } from 'src/environments/environment.development';
import { CookieServiceWrapper } from 'src/app/cookie-service-wrapper.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isAdmin:boolean = false;

  @Input() userDeprovPage = false;
  showNotificationCount:any = false;
  notificationsCount: number = 0;
  notificationToggle= false;
  notifications:any[]=[];
  expandless:boolean = false;
  expandmore:boolean=  true;
  searchId:string = "expand_more";
  loader:boolean =false;
  loadMore:boolean = true;

  updates:any[] =[]; 
  constructor(private notificationService:NotificationService, 
    private engineService:EngineService, 
    private cookieService: CookieServiceWrapper, private router:Router,
    private eventLoggingService:Customeventlogging,
    private sharedService: SharedService,
    private authService:AuthService) { }
   
  nickName:any;
  fullName:any;

  userId:any;
  showHeader=false;

  ngOnInit(): void {


    if(this.router.url === '/landing'){
      this.showHeader = true;
    }

    this.userId = this.cookieService.get('usi');
    this.nickName = this.cookieService.get('sn');
    this.fullName = this.cookieService.get('fn');
       // console.log("document cookie", document.cookie.split(";"))
    // console.log("I am called");
    var isAdmin:any = this.cookieService.get('isAdmin');
    // console.log("isAdmin", isAdmin);

    if(isAdmin == 1){
      this.isAdmin = true;
    }
    this.getSavedSearchesNotificationsCount();
  }

   getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  navigateToUserDeprovision(){
    window.open(CONFIG.USER_DEPROVISIONING.REDIRECT_URL, "_blank");
    this.eventLoggingService.logCustomEvent('Landing Page',{ userId: this.cookieService.get('usi'),Action:'Click'},'Click on Admin');

  }

  expandNotificationRecord(notification:any){
            
  
    if(notification.collapse == "expand_more"){
      notification.collapse = "expand_less";
      this.getUpdatesBasedonSavedSearches(notification.ID);
      return;
      
    }
    if(notification.collapse  == "expand_less"){
      notification.collapse  = "expand_more";
      this.updates =[];
      return;
      
    }
    
  }

  async loadMoreNotifications(){

     var requestPayload =  `{
      "userId": `+this.userId+`,
      "markAsViewed": false,
      "updateId": "",
      "loadmore":"1",
      "loadall":"`+1+`"
    }`;

    var response:any =  await  this.notificationService.loadMoreNotifications(requestPayload);
    // console.log("response loadMoreNotifications", response);
    this.notifications = response;
    this.getSavedSearchesNotificationsCount();
  }
  getSavedSearchesNotificationsCount(){
    let requestPayload = {
      "userId": this.userId,
      "markAsViewed": false,
      "updateId": "",
      "getCount": true,
      "loadall": "0"
  };
      this.notificationService.getNotificationsCount(requestPayload).subscribe(
        {
          next: (response:any) => 
          {
            // console.log("response", response);
            this.notificationsCount = response?.[0].count;
            if (this.notificationsCount > 0) {
              this.showNotificationCount = true
            }
          },
          error: (e) => console.error(e),
          complete: () => console.info('complete') 
      }
      )
}

   async showNotificationRelatedResults(recordId:any){

    recordId = parseInt(recordId);

    // alert("CALLED");
    

         
    var requestPayload = `{"userId":"`+this.userId+`","markAsViewed":true,"updateId":"`+recordId+`"}`;
      var response=   await this.notificationService.markNotificationAsViewed(requestPayload);
      // console.log("response", response);
   }
  async getUpdatesBasedonSavedSearches(searchId:any){
          let offset =0;
          let response:any;
          let counter = 0;
     do {

      let requestPayload =
      {
        "userId": this.userId,
        "markAsViewed": false,
        "updateId": "",
        "searchId": searchId,
        "offset": offset,
        "loadall": "0"
    }
        response = await (await this.notificationService.getNotificationsBasedOnSearchId(requestPayload)).toPromise();
       // console.log("response check", response);
       this.updates = [...this.updates, ...response];
       // console.log("updates length:", this.updates.length);
       offset = offset + response.length;
             if(response.length == 10){
          this.loader = true;
             }
             else{
              this.loader = false;
             }
        
       await this.sleep(5000);
       counter++;
       // console.log("do block called",counter);
     }while(response.length == 10)
     this.loader = false;
   }

   async removeNotification(updateId:any){    
      var requestPayload = {
        "updateId": parseInt(updateId),
        "search": false,
        "id": ""
    }
     var response =  await  this.notificationService.removeNotificationUpdate(requestPayload);
     // console.log("response removeNotification", response);
      this.updates.forEach(
          (update,i) =>{
                             if(update.ID  == updateId){
                                 this.updates.splice(i,1);
                             }
          }
      )
   }
    sleep(ms:number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
   openNotifications(){
    // console.log("Open Notifcations called");
    this.notificationToggle = true;
          let requestPayload = {
          "userId": this.userId,
          "markAsViewed": false,
          "updateId": "",
          "loadmore": "",
          "loadall": "0"
      };
          this.notificationService.getNotifications(requestPayload).subscribe(
            {
              next: (response:any) => {
                // console.log(response);
                  let   allNotifications = response.map((notification:any) =>{
                            
                            notification.collapse= "expand_more";
                          
                            return notification;
                  }) 
                 this.notifications= allNotifications;
                }
          ,
              error: (e) => console.error(e),
              complete: () => console.info('complete') 
          })
         
   }
   async removeSavedSearchFromNotifications(patternId:any){
    var requestPayload = `{
      "id": `+patternId+`,
      "search":true,
      "updateId":""
    }`;
    var response = await this.notificationService.removeSavedSearchFromNotifications(requestPayload);
     // console.log("removeSavedSearchFromNotifications", response);
     this.notifications.forEach(
      (notification,i)=>{
                                 if(notification.ID = patternId){
                this.notifications.splice(i,1);
                                 }
      }
     )
   }
   closeNotifications(){
    this.notificationToggle = false;
   }

   gotoLanding(){
    this.cookieService.set("redirect","",1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
    this.eventLoggingService.logCustomEvent('Landing Page',{ userId: this.cookieService.get('usi'),Action:'Click'},'Click on Landing Page Logo');
    // console.log("hello")
    // this.router.navigateByUrl("/landing");
    window.open(CONFIG.LANDING.AUTH,"_self");

    // custom Events

   
    
   }

   async showDrive(){
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      // console.log("setting isInMaintenance flag");
      var resp = await this.authService.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
            // resp = Boolean(resp);
             // console.log("typeof resp", typeof resp);
             // console.log("resp value", resp);
           if(resp == "true"){
            this.router.navigateByUrl("/maintenance");
            return;
           }
         }
    this.cookieService.set("cfl","mydrive");
    this.router.navigateByUrl("/", 
    {
     state: {
      }
     })
   }

   async showCollections(){
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      // console.log("setting isInMaintenance flag");
      var resp = await this.authService.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
             //resp = Boolean(resp);
             // console.log("typeof resp", typeof resp);
           if(resp == "true"){
            sessionStorage.setItem("isInMaintenance", "true");
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    this.cookieService.set("cfl","collections");
    this.router.navigateByUrl("/", 
    {
     state: {
      }
     })
   }

   async showSavedSearches(){
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      // console.log("setting isInMaintenance flag");
      var resp = await this.authService.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
             resp = Boolean(resp);
             // console.log("typeof resp", typeof resp);
           if(resp == "true"){
            sessionStorage.setItem("isInMaintenance", "true");
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    this.cookieService.set("cfl","savedsearch");
    this.router.navigateByUrl("/", 
    {
     state: {
      }
     })
   }

   async showNotifications(){
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      // console.log("setting isInMaintenance flag");
      var resp = await this.authService.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
             resp = Boolean(resp);
             // console.log("typeof resp", typeof resp);
           if(resp == "true"){
            sessionStorage.setItem("isInMaintenance", "true");
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    this.cookieService.set("cfl","notifications");
    this.router.navigateByUrl("/", 
    {
     state: {
      }
     })
   }

   
}
