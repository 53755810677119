<div
  class="overlay"
  id="overlay">

  <div
  class="onedrive_registration">
         <div style="font-weight: 700;
         font-size: 16px;
         font-family: invention;">Grant Permission</div>
          <div style="display: flex;flex-direction: column;">
                  <div style="padding: 10px 0px 9px;
                  font-family: invention;
                  font-size: 14px;
                  font-weight: 400;">{{title}}</div>
                  <div     style="font-size: 11px;
                  /* margin-top: -16px; */
                  padding-bottom: 18px;
                  color: red;
                  font-weight: 400;">{{contactMessage}}</div>
                  <div  style="display: flex; justify-content: center; gap:20px" *ngIf="actionsFlag == 1">
                    <div><button class="docFavCancleBtn" (click)="closeTab()">Close</button> </div>
                    <div><button class="docFavSaveBtn" (click)="register()">Register</button></div></div>
                  <div *ngIf="actionsFlag == 2" style="display: flex; justify-content: center;"><button class="docFavSaveBtn" (click)="closeTab()">Close</button></div>
                  <div class="disclaimer">{{infoMSG}}</div>
          </div>
        <!-- If the content exceeds the container height, a scroll bar will appear -->
      </div>
  </div>

  

