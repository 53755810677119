<!-- <h1>Angular Samples</h1> -->

<!-- <app-atomic-angular-page></app-atomic-angular-page> -->
<!-- <nav>
  <ul>
    <li>
      <a routerLink="/atomic-angular" routerLinkActive="active">Sample with Atomic Angular (@coveo/atomic-angular)</a>
    </li>
  </ul>
</nav> -->

<router-outlet></router-outlet>