import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONFIG, ENVIRONMENT } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ViewpageService {

  constructor(private httpClient:HttpClient) { }

  generateTokenForViewPage(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.VIEWPAGE.TOKEN_URL, requestPayload);
  }

  downloadFile(requestPayload:any): Observable<any>{
    return this.httpClient.post(ENVIRONMENT.VIEWPAGE.DWONLOAD_URL, requestPayload);
  }
}
