import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removehtml'
})
export class RemovehtmlPipe implements PipeTransform {

  transform(value: string): string {
      if(!value){
        return value;
      }
    return value.replace(/<\/?[^>]+(>|$)/g,"").substring(0,1000);
  }

}
