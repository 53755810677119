import { Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-vault-file-download',
  templateUrl: './vault-file-download.component.html',
  styleUrls: ['./vault-file-download.component.css']
})
export class VaultFileDownloadComponent {
  blob: Blob | undefined;

  constructor(private readonly http: HttpClient) {}

  ngOnInit(): void {

    this.download();

  }

  download() {
    this.downloadFile()
      .subscribe(data => saveAs(data, 'Example.pdf'));
  }
  
  downloadFile() {
    const httpOptions = {
      headers : {'Content-Type':'application/json',
      'Authorization':'',
      'responseType': 'blob'
    },
    }
    return this.http.get('https://sb-msd-medcomms-dev.veevavault.com/api/v21.2/objects/documents/102/renditions/viewable_rendition__v?steadyState=true>file.pdf',httpOptions);
  }


  // getPdf() {

  //   const httpOptions = {
  //     responseType: 'blob' as 'json'
  //   };
  
  //   return this.http.get(`https://sb-msd-medcomms-dev.veevavault.com/api/v21.2/objects/documents/102/renditions/viewable_rendition__v?steadyState=true>file`, httpOptions);
  // }

}
