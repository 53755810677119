import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AtomicAngularPageComponent } from './atomic-angular-page/atomic-angular-page.component';
import { ResultsPageComponent } from './results-page/results-page.component';
import { ViewpageComponent } from './../app/viewpage/viewpage.component';
import { OneDriveRegistrationComponent } from './one-drive-registration/one-drive-registration.component';
import { HomeComponent } from './home/home.component';
import { ExportToExcelComponent } from './export-to-excel/export-to-excel.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { SavedsearchComponent } from './savedsearch/savedsearch.component';
import { UsersettingsComponent } from './usersettings/usersettings.component';
import { CsnShareComponent } from './csn-share/csn-share.component';
import { UserDeprovisioningComponent } from './user-deprovisioning/user-deprovisioning.component';
import { ErrorComponent } from './error/error.component';
import { OneDriveRegisterationMultipleComponent } from './one-drive-registeration-multiple/one-drive-registeration-multiple.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ExternalUrlSupportComponent } from './external-url-support/external-url-support.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { VaultFileDownloadComponent } from './vault-file-download/vault-file-download.component';

// const routes: Routes = [];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }


const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo:'landing-page'
  // },
  {
    path: 'index.html',
    component: ExternalUrlSupportComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'share/:feature/:id',
    component: CsnShareComponent,
  },
  {
    path: 'settings',
    component: UsersettingsComponent,
  },
  {
    path: 'searches',
    component: SavedsearchComponent,
  },
  {
    path: 'landing',
    component: AtomicAngularPageComponent,
  },
  {
    path: 'auth',
    component: AuthenticationComponent,
  },
  {
    path: 'viewpage/:doc/:docTitle/:s',
    component: ViewpageComponent,
  },
  {
    path: '',
    component: ResultsPageComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'odregister',
    component: OneDriveRegistrationComponent,
  }, 
  {
    path: 'odregister_multiple',
    component: OneDriveRegisterationMultipleComponent,
  }, 
  {
    path:'exporttoExcel', 
    component: ExportToExcelComponent
  },
  {
    path:'userdep',
    component:UserDeprovisioningComponent
  },
  {
    path:'error',
    component:ErrorComponent
  },
  {
    path:'confirmation',
    component:ConfirmationComponent
  },
  {
    path:'download',
    component:VaultFileDownloadComponent
  },
  {
    path:'**',
    component:AtomicAngularPageComponent
  },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
