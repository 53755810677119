import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONFIG, ENVIRONMENT } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class UserDeprovisioningService {

  constructor(private httpClient:HttpClient) { }

  getNotificationsCount(){

    return  this.httpClient.post(CONFIG.USER_DEPROVISIONING.API_URL,{});

  }

}
