import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CollectionsComponent } from '../collections/collections.component';
import { CollectionsService } from '../collections.service';
import { AuthService } from '../auth.service';
import { SavedsearchService } from '../savedsearch.service';
import { ENVIRONMENT } from 'src/environments/environment.development';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';

@Component({
  selector: 'app-usersettings',
  templateUrl: './usersettings.component.html',
  styleUrls: ['./usersettings.component.css']
})
export class UsersettingsComponent implements OnInit, AfterViewInit{

  tab=2;
  savedSearchesTab = true;
  collectionsTab = false;
  userSettingsTab = false;
  collectionslist:any = [];
  userId:any;
  searchSettingsFeature:any;
  settingsData : any;
  
  savedSearchesList:any;
  sortSelected:any;
  // searchOptions = ['Antibacterial', 'Antiviral', 'Cardiovascular', 'Endocrinology', 'Immunology', 'Neuroscience', 'Oncology', 'Respiratory', 'Vaccines'];
  // searchRegions = ['Asia Pacific (other than Japan and China)', 'China', 'Europe, Middle East, and Africa', 'Japan', 'Latin America', 'United States'];

   // Page Number variables
  // pageNumbers = [10, 20, 30, 40, 50];
  // selectedPageNumber: number = this.pageNumbers?.[0]; 
  // selectedRegion: boolean[] = new Array(this.searchRegions.length).fill(false);

  @ViewChild(CollectionsComponent) collecCmp!: CollectionsComponent;

  constructor(private route: ActivatedRoute,private cookieService:CookieServiceWrapper, private collectionsService: CollectionsService, private router: Router, private auth: AuthService, private savedSearchService:SavedsearchService){

    // this.auth.checkAuthetication();
    

  }
  ngAfterViewInit(): void {
    // this.loadAllCollections();
    // this.loadAllSearchesServer();
  }

 loadDefaultSettings()
  {
    let payload = {
      userID: this.userId,
    };
    
    // Example of the resulting payload
    // console.log(payload);
    this.collectionsService.getUSerSettings(payload).subscribe(async (data)=>{
      // console.log("data",data);
      let finalData = await JSON.parse(data.body);
      // console.log("finalData",finalData);

      const therapeuticAreaData = finalData?.[0].THERAPEUTIC_AREA_JSON;
      // console.log("therapeuticAreaData",JSON.parse(therapeuticAreaData));
      let selectedOptionsUser = JSON.parse(therapeuticAreaData);
      // console.log("selectedOptions",selectedOptionsUser);



// Loop through selectedOptionsUser array
for (let i = 0; i < selectedOptionsUser.length; i++) {
  // Check if selected property is true
  if (selectedOptionsUser[i].selected) {
      // Push true to selectedOptions array if selected is true
      this.selectedOptions.push(true);
  } else {
      // Push false if selected is false
      this.selectedOptions.push(false);
  }
}

// console.log("selectedOptions", this.selectedOptions);
      // this.searchOptions = therapeuticAreaData.map(option => option.name);
      // console.log("selectedOptionsUser", this.selectedOptions);
const resultsperpagebyuser = finalData?.[0].RESULTS_PER_PAGE;
let selectedOresultsperpagebyUser = JSON.parse(resultsperpagebyuser);
let getPageNumber = selectedOresultsperpagebyUser.filter(x => x.selected == true);
// console.log("getPageNumber",getPageNumber);
this.selectedPageNumber =  getPageNumber?.[0].value;
// console.log("selectedPageNumber",this.selectedPageNumber);
localStorage.setItem('resultsperpage',this.selectedPageNumber)



const sorting  = finalData?.[0].COLLECTION_SORTING;
// let filteredData = selectedOresultsperpagebyUser.filter()
// console.log("sorting",sorting);
let selectedSorting = JSON.parse(sorting)
// console.log("sorting",selectedSorting);

// Loop through selectedOptionsUser array
let sortedItem = selectedSorting.filter(x => x.selected == true)
this.selectedSorting  = sortedItem?.[0].label;
// console.log("selectedSorting", this.selectedSorting);
// for (let i = 0; i < selectedSorting.length; i++) {
//   // Check if selected property is true
//   if (selectedSorting[i].selected) {
//     // Push the index to selectedOptions array
//     // this.selectedPageNumber.push(i);
//     // console.log("selectedPageNumber", this.selectedPageNumber);
//     this.selectedSorting = this.sortingList[i];

//   }
// }


      
      // const sortingListData = JSON.parse(responseData.COLLECTION_SORTING);
      // this.sortingList = sortingListData.map(option => option.label);
      
      // const resultsPerPageData = JSON.parse(responseData.RESULTS_PER_PAGE);
      // this.pageNumbers = resultsPerPageData.map(option => option.value);
      // this.selectedPageNumber = resultsPerPageData.find(option => option.selected).value;
      // this.snackBarFlag = true;
      // await this.sleep(5000);
      // this.snackBarFlag = false;
          //  this.collectionslist= [];
     })
  }

  updateSelectedOption(index: number, checked: boolean): void {
    this.selectedOptions[index] = checked;
}


snackBarFlagCancel : boolean = false

  async cancel()
{
  this.snackBarFlagCancel = true;
  await this.sleep(2000);
  this.snackBarFlag = false;
  if (!localStorage.getItem('reload')) {

    localStorage.setItem('reload', 'no reload')

    location.reload();

  } else {

    localStorage.removeItem('reload')

  }
}
  

  
  async ngOnInit() {
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      var resp = await this.auth.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
           if(resp == "true"){
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
     // console.log("this.cookieService.get('rkymnt')", this.cookieService.get('rkymnt'));
    if(this.cookieService.get('rkymnt')){
      // this.cookieService.deleteAll('/', ENVIRONMENT.DOMAIN.HOST, true, 'Strict');
      // this.cookieService.set("lat","y");
      // this.cookieService.set("lat","y");
      this.cookieService.set('lat','y', 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
      this.router.navigate(['/auth']);
    }

    if (!localStorage.getItem('reload')) {

      localStorage.setItem('reload', 'no reload')

      location.reload();

    } else {

      localStorage.removeItem('reload')

    }

       this.savedSearchesTab = false;
        this.collectionsTab = false;
        this.userSettingsTab = true;
        this.searchSettingsFeature = ENVIRONMENT.searchsettings;
      this.loadSettingsData();

    this.userId = this.auth.getUserId();
    this.route.queryParams.subscribe(async params => {
      this.tab = params['tab'];
      if(this.tab==1){

        this.savedSearchesTab = false;
        this.collectionsTab = false;
        this.userSettingsTab = true;

      }else if(this.tab==2){

        this.savedSearchesTab = false;
        this.collectionsTab = true;
        this.userSettingsTab = false;
        this.loadAllCollections();

      }else if(this.tab==3){

        this.savedSearchesTab = true;
        this.collectionsTab = false;
        this.userSettingsTab = false;

      }


      // this.router.navigate(
      //   [],
      //   {
      //     queryParams: {
      //       tab: null, 
      //     },
      //     queryParamsHandling: 'merge',
      //   }
      // )



      
    });
    this.loadDefaultSettings();

  }

  THERAPEUTIC_AREA_JSON : any;
  searchOptions: any;
  selectedOptions: boolean[] = [];
  sortingList : any;
  pageNumbers : any;
  selectedPageNumber: any = []; 
  selectedSorting: string = 'mostrecent';

  loadSettingsData()
  {

  this.collectionsService.getAllSettings().subscribe((data)=>{
  this.settingsData = JSON.parse(data?.body);
  // console.log("settingsData", this.settingsData?.[0] );
  this.THERAPEUTIC_AREA_JSON = JSON.parse(this.settingsData?.[0].THERAPEUTIC_AREA_JSON);
  // console.log("THERAPEUTIC_AREA_JSON", this.THERAPEUTIC_AREA_JSON);
  this.searchOptions = this.THERAPEUTIC_AREA_JSON;
  this.sortingList = JSON.parse(this.settingsData?.[0].COLLECTION_SORTING);
  // console.log("sortingList", this.sortingList);

  
  // this.RESULTS_PER_PAGE = JSON.parse(this.settingsData?.[0].RESULTS_PER_PAGE);
  this.pageNumbers = JSON.parse(this.settingsData?.[0].RESULTS_PER_PAGE);
  // console.log("pageNumbers", this.pageNumbers);
  // this.selectedSorting = this.sortingList?.[0];

  // this.selectedOptions = new Array(this.searchOptions.length).fill(false);

  //  this.collectionslist= [];
 })
  }


  tabChange(tab){
    this.tab = tab;
    if(this.tab==1){

      this.savedSearchesTab = false;
      this.collectionsTab = false;
      this.userSettingsTab = true;

    }else if(this.tab==2){

      this.savedSearchesTab = false;
      this.collectionsTab = true;
      this.userSettingsTab = false;
      this.loadAllCollections();

    }else if(this.tab==3){

      this.savedSearchesTab = true;
      this.collectionsTab = false;
      this.userSettingsTab = false;
      this.loadAllSearchesServer();
    }
  }
  loadAllCollections(){
    // var useridDecrypted = this.Auth.getUserId();
    // var tab = this.sourceFlag;
    // if(tab == "GMSA News"){
    //   tab = "SP"
    // }
    // else if(tab == "Veeva Vault MedComms"){
    //   tab = "VVMC"
    // }
    // // console.log("tab",tab);
    var data = {
          "collectionId": "",
          "userId": this.userId,
          "tab": ""
      }
      this.collectionsService.getAllCollections(data).subscribe((collections)=>{
      this.collectionslist = collections.collectionlist;
      // this.collectionslist.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
      this.collectionslist.sort((a,b) => (a.name.localeCompare(b.name)));
      // this.collectionslist.sort();
      // this.collectionslist.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
      // this.collectionslist.sort((a,b) => (b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : ((a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : 0))
      // console.log("collectionslist", this.collectionslist);
      //  this.collectionslist= [];
     })
  }


  getCollectionsSorted(order:any){
    console.log("order-->"+order);
    this.sortSelected = order;
    var data = {
      "collectionId": "",
      "userId": this.userId,
      "tab": ""
  }
    this.collectionsService.getAllCollections(data).subscribe((collections)=>{
      this.collectionslist = collections.collectionlist;
      if(order==='ascending'){
        // this.collectionslist.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
        this.collectionslist.sort((a,b) => (a.name.localeCompare(b.name)));
      }else if(order==='descending'){
        // this.collectionslist.sort((a,b) => (b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : ((a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : 0))
        this.collectionslist.sort((a,b) => (b.name.localeCompare(a.name)));
      }else if(order==='date-ascending'){
        this.collectionslist.sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      }else if(order==='date-descending'){
        this.collectionslist.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      }
    })
    
  }


  getSavedSearchessSorted(order:any){
    console.log("order--->"+order);
    this.sortSelected = order;

    var tab = 'VVMC'
    var data = `{
      "userISID": "",
      "userId":`+ this.userId + `,
      "source":"ALL",
      "searchId":0
    }`

    this.savedSearchService.getAllPatterns(data).subscribe(searches => {
      this.savedSearchesList = searches.searchPatterns;
      if(order==='ascending'){
        // this.savedSearchesList.sort((a,b) => (a.patternName.toLowerCase() > b.patternName.toLowerCase()) ? 1 : ((b.patternName.toLowerCase() > a.patternName.toLowerCase()) ? -1 : 0))
        this.savedSearchesList.sort((a,b) => (a.patternName.localeCompare(b.patternName)));
      }else if(order==='descending'){
        // this.savedSearchesList.sort((a,b) => (b.patternName.toLowerCase() > a.patternName.toLowerCase()) ? 1 : ((a.patternName.toLowerCase() > b.patternName.toLowerCase()) ? -1 : 0))
        this.savedSearchesList.sort((a,b) => (b.patternName.localeCompare(a.patternName)));
      }else if(order==='date-ascending'){
        this.savedSearchesList.sort((a,b) => new Date(a.lastupdateddate).getTime() - new Date(b.lastupdateddate).getTime())
      }else if(order==='date-descending'){
        this.savedSearchesList.sort((a,b) => new Date(b.lastupdateddate).getTime() - new Date(a.lastupdateddate).getTime())
      }
    })
  }


  loadAllSearchesServer() {

    var tab = 'VVMC'
    var data = `{
      "userISID": "",
      "userId":`+ this.userId + `,
      "source":"ALL",
      "searchId":0
    }`

    this.savedSearchService.getAllPatterns(data).subscribe(searches => {
      this.savedSearchesList = searches.searchPatterns;
      this.savedSearchesList.sort((a,b) => (a.patternName.toLowerCase() > b.patternName.toLowerCase()) ? 1 : ((b.patternName.toLowerCase() > a.patternName.toLowerCase()) ? -1 : 0))
    })

  }
  
  backToResults() {
    this.router.navigate(['']).then(() => {
      // After navigation is complete, perform a hard reload
      window.location.reload();
    });
  }

  snackBarFlag : boolean = false;

 async  updateSearchSettings()
  {
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      var resp = await this.auth.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
           if(resp == "true"){
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    let therapeuticAreaJson: { name: string, selected: boolean }[] = [];
  
    // Assuming this.searchOptions and this.selectedOptions have the same length
    for (let i = 0; i < this.searchOptions.length; i++) {
      therapeuticAreaJson.push({
        "name": this.searchOptions[i],
        "selected": this.selectedOptions[i] || false
      });
    }


    // console.log("therapeuticAreaJson",therapeuticAreaJson);  
  // Constructing results per page payload
  let resultsPerPage: { value: number, selected: boolean }[] = [];
  this.pageNumbers.forEach((pageNumber: number) => {
    resultsPerPage.push({
      "value": pageNumber,
      "selected": pageNumber === this.selectedPageNumber
    });
  });
  
  // Constructing collection sorting payload
  let collectionSorting: { label: string, expression: string, selected: boolean }[] = [];
  this.sortingList.forEach((sortingOption: string) => {
    collectionSorting.push({
      "label": sortingOption,
      "expression": sortingOption.toLowerCase(),
      "selected": sortingOption.toLowerCase() === this.selectedSorting.toLowerCase()
    });
  });
  
  // Constructing the final payload
  let payload = {
    USER_ID: this.userId,
    therapeuticAreaJson: therapeuticAreaJson,
    resultsPerPage: resultsPerPage,
    collectionSorting: collectionSorting
  };
  
  // Example of the resulting payload
  // console.log(payload);
  this.collectionsService.updateSettings(payload).subscribe(async (data)=>{
    // console.log("data",data);
    this.snackBarFlag = true;
    this.loadDefaultSettings()
    await this.sleep(5000);
    this.snackBarFlag = false;
        //  this.collectionslist= [];
   })
  }
  sleep (ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }
}
