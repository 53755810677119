import { AfterViewInit, inject, ChangeDetectorRef, Component, Injectable, Input, NgZone, OnInit, ViewChild } from '@angular/core';

import { DateFacetValue,Facet, FacetValue, FacetValueState, SearchBox, ToggleSelectFacetValueActionCreatorPayload, UpdateDateFacetValuesActionCreatorPayload, UpdateFreezeCurrentValuesActionCreatorPayload, buildFacet, loadDateFacetSetActions, loadFacetSetActions, loadSearchActions, loadSearchAnalyticsActions } from '@coveo/headless';
import { EngineService } from '../engine.service';
 

import {

  SearchEngine,
  loadAdvancedSearchQueryActions
} from '@coveo/headless';

import {AtomicText, Controller, loadContextActions } from '@coveo/atomic-angular';
import { Result, ResultList } from '@coveo/headless';
import { buildResultList, buildController, ResultListState,loadQueryActions } from '@coveo/headless';
import { CryptoService } from '../crypto.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SavedsearchService } from '../savedsearch.service';
import { SharedService } from '../shared.service';
import { AuthService } from '../auth.service';
import { SavedsearchComponent } from '../savedsearch/savedsearch.component';
import { ResultListComponent } from '../result-list/result-list.component';
import { CollectionsComponent } from '../collections/collections.component';
import { Customeventlogging } from '../customevent-logging.service';
import { CONFIG } from 'src/environments/environment.development';
import { ENVIRONMENT } from 'src/environments/environment.development';
import {DOCUMENT} from '@angular/common';
import { CollectionsService } from '../collections.service';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import { ConfigService } from '../config.service';
import { DomSanitizer } from '@angular/platform-browser';
 

@Component({
  selector: 'app-results-page',
  templateUrl: './results-page.component.html',
  styleUrls: ['./results-page.component.css']
})



export class ResultsPageComponent implements AfterViewInit, OnInit {
  @Injectable({
    providedIn: 'root'
  })
  @ViewChild('searchInterface')
  searchInterface!: HTMLAtomicSearchInterfaceElement;
  mySearchBox?: SearchBox;
  private engine!: SearchEngine;
  raw: any ;
  floatingPopupFlag = false;
  SavedSearchAddPopupShow = false;
  SavedSearchAddPopupShowFlag = false
  saveSearchPopupTitle:any;
  saveSearchPopupMesage:any;
  SavedSearchMsg = false;
  private dom:any = inject(DOCUMENT);
  isExternalUseEnabledMain = false;
  historyState:any;
  

  @ViewChild('atomictext')  atomicText?: AtomicText; 
  @ViewChild('savedSearch')  savedSearchCmp?: SavedsearchComponent;
  @ViewChild('restList')  resultListCmp?: ResultListComponent;
  public resultList: ResultList | undefined;
  public controller: Controller | undefined;

  @ViewChild('collectionsCmp')  collectionsCmp?: CollectionsComponent;

  resultsPage = true;
  settingsPage = false;
  export= false;
  exportPopUp = true;

   closeExportPopup(){
    this.export = false;
    //this.exportPopUp = false;
   }

   exportToExcel(){
    // console.log("export to excel");
    this.export = true;
}


  loadingIndicater = false;
  constructor(private ngZone: NgZone ,private eventLoggingService : Customeventlogging,private configService: ConfigService,private sanitizer:DomSanitizer,
    private router: Router,private engineService: EngineService, private cookieService: CookieServiceWrapper,private cryptoservice:CryptoService, private savedSearch: SavedsearchService, private sharedService:SharedService, 
    private authService:AuthService, private cd: ChangeDetectorRef,private collectionsService : CollectionsService) {

    this.loadingIndicater = true;
    
    this.historyState = history.state;

    // var redirect  = this.cookieService.get('redirect');
    // if(!redirect){
      let redirect = this.router.url;
      if(redirect.length>2){
        this.cookieService.set("redirect",this.router.url,1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
      }
    // }
    
    
    // // console.log(this.router.getCurrentNavigation()?.extras.state);
    // this.cookieService.set("redirect",this.router.url,1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');

    // if(sessionStorage.getItem("isRockyMountainUser") == null || sessionStorage.getItem("isRockyMountainUser") == "null" ){
    //   // this.router.navigate(['/auth']);

    //   if(!this.cookieService.get("lan")){
    //     // console.log("this.router.url-->"+this.router.url);
    //     // this.cookieService.set("redirect",this.router.url,1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
    //     // alert("this.router.url-->"+this.router.url);
    //     this.router.navigateByUrl("/landing", 
    //      {
    //       state: {
    //        }
    //       })
    //     }else{
    //       this.authService.checkAuthetication();
    //     }
        
    //  }
    //  else{
    //   var userType = sessionStorage.getItem("isRockyMountainUser");
    //    if(userType == "true"){
    //         this.isRockyMountainUser = true;
    //        //this.dom.getElementById("facets").style.display = "none";
    //        // console.log("facets id", this.dom.getElementById("facets"));
    //    }
    //    else{
    //     this.isRockyMountainUser = false;
        
        
    //    }
    //  }
    


    // var enAjx  = this.cookieService.get('ajs');
    // if(!enAjx){
    //   this.router.navigate(['/auth']);
    // }

    // if(!this.cookieService.get("lan")){
    //   this.router.navigateByUrl("/landing", 
    //    {
    //     state: {
    //      }
    //     })
    //   }else{
    //     this.authService.checkAuthetication();
    //   }



  }


  renameCollection(collection){
    this.collectionsCmp?.renameCollection(collection);
  }

  deleteCollection(collection){
    this.collectionsCmp?.deleteCollectionWindow(collection);
  }
  shareCollection(collection){
    this.collectionsCmp?.shareCollection(collection);
  }

  SavedSearchMsg1(){
    this.SavedSearchMsg = true;
  }

  isRockyMountainUser = false;

  SavedSearchTextMsg;
  ngOnInit(): void { 

    this.loadConfiguration();
    let redirect = this.router.url;
    if(redirect.length>2){
      this.cookieService.set("redirect",this.router.url,1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
      // const urlContains = redirect.includes('redirect');
      // if(urlContains){
      //   this.cookieService.set("redirect","",1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
      // }
    }



    if(sessionStorage.getItem("isRockyMountainUser") == null || sessionStorage.getItem("isRockyMountainUser") == "null" ){
      // this.router.navigate(['/auth']);

      if(!this.cookieService.get("lan")){
        // console.log("this.router.url-->"+this.router.url);
        // this.cookieService.set("redirect",this.router.url,1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
        // alert("this.router.url-->"+this.router.url);
        this.router.navigateByUrl("/landing", 
         {
          state: {
           }
          })
        }else{
          this.authService.checkAuthetication();
        }
        
     }
     else{
      var userType = sessionStorage.getItem("isRockyMountainUser");
       if(userType == "true"){
            this.isRockyMountainUser = true;
           //this.dom.getElementById("facets").style.display = "none";
           // console.log("facets id", this.dom.getElementById("facets"));
       }
       else{
        this.isRockyMountainUser = false;
        
        
       }
     }







    this.loadDefaultSettings();

    // console.log("collection name", this.collection);
    this.resultsPerPageValue = localStorage.getItem("resultsperpage");
    // ****Exter use flag enable and disbale***
    const engine = this.engineService.getEngibeObj();
    var facet = buildFacet(engine, {options: {field: 'contentintendeduse',facetId:'contentintendedusetmp'}});
    facet.subscribe(() => { 
      var isExternalUseEnabled = false;
      const facetState = engine.state.search.response.facets;
       for(let facet of facetState){
        // // console.log("facet-->"+JSON.stringify(facet));
        var facetValuesList = facet.values;
        if(facetValuesList.length>0){
          for(var facetValueObj of facet.values){
            if(facetValueObj.state=='selected'){
              if(facet.facetId==='contentintendeduse' && facetValueObj['value'].includes('External Use')){
                // // console.log("select facet.facetId ==> "+facet.facetId);
                // // console.log("select facet.facetId ==> "+facetValueObj['value']);
                 isExternalUseEnabled = true;
              }else{

              }
            }
          } 
        }
      }
      if(!isExternalUseEnabled){
        this.isExternalUseEnabledMain = false;
      }else{
        this.isExternalUseEnabledMain = true;
      }
    });

    // this.cookieService.set("lat","");
    this.cookieService.set('lat',"", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');

    // this.router.navigateByUrl("/error", 
    // {
    //  state: {
    //    message: "You do not have access to the Search Navigator"
    //  }
    // })

      this.loadingIndicater = true;
      

      // if(!this.cookieService.get("redirect")){
        // if(!this.cookieService.get("lan")){
        //   this.router.navigateByUrl("/landing", 
        //    {
        //     state: {
        //      }
        //     })
        //   }
      // }
      // this.cookieService.set("redirect","",1,"/");
      
    
      this.sharedService.getSavedSearch().subscribe(data=>{
        this.resultListCmp?.clearshowAllSelectedBtn();
        this.ngZone.run(() =>{

          if(data.feature=='savedSearch'){

            if(data.data.statusCode == 1003){
              this.myOneDriveflag = false;
              this.myCollectionsflag = false;
              // this.SavedSearchMsg = true;
              this.SavedSearchTextMsg = data.data.statusDescription;
              // this.saveSearchPopupMesage = data.data.statusDescription;
              this.savedSearchCmp?.enableBtns();
            }else if(data.data.statusCode == 1000){

              this.SavedSearchAddPopupShow = false;
              this.myOneDriveflag = false;
              this.myCollectionsflag = false;
              this.SavedSearchMsg = true;
             // this.saveSearchPopupMesage = "Your Search has been successfully saved. Notifications will be received when new or updated news links or documents meet the saved search criteria."

             this.saveSearchPopupMesage = CONFIG.RESULTS_PAGE.SAVESEARCH_POPUP_MESSAGE;

            }

            

            //  if(data.data.statusCode==1003){
            //   this.savedSearchCmp?.showMsg(true,data.data.statusDescription);
            //   this.saveSearchPopupMesage = data.data.statusDescription;
            //  }
            //  if(data.data.statusCode==1000){
            //   this.saveSearchPopupMesage = "Your Search has been successfully saved. Notifications will be received when new or updated news links meet the saved search criteria."
            //  }else{
            //   this.saveSearchPopupMesage = "Your Search has been successfully saved. Notifications will be received when new or updated news links meet the saved search criteria."
            //  }
          }else if(data.feature=='loadSearch'){
            this.myOneDriveflag = false;
            this.myCollectionsflag = false;
          }

        })

        
      });


      this.sharedService.getCollectionData().subscribe(data=>{
        this.resultListCmp?.clearshowAllSelected();
        this.collection = data.collection;
      });


      this.sharedService.getCollectionUpdate().subscribe(data=>{
        // alert("this.collection-->"+JSON.stringify(this.collection));
        // this.loadSpecificCollection(this.collection);
        this.myCollectionsflag = false;
       // this.resultListCmp?.clearshowAllSelected();
      });


      this.sharedService.getDeletedCollection().subscribe(data=>{
        this.backToResultsFromMyMyCollections();
      });

      this.sharedService.getRenameCollection().subscribe(data=>{
        this.collection.name = data.collectionName;
      });

      this.sharedService.getSharedCollectionSave() .subscribe(data=>{
        this.collectionShareSaveBtn = true;
      });

      this.sharedService.getclearAll().subscribe(data=>{
        this.myOneDriveflag = false;
        this.myCollectionsflag = false;
      });


      
  

    // alert("history.state===>"+JSON.stringify(history.state.pattern));
    
    // if(history.state.pattern){
    //   var search = history.state.pattern;
    //   this.loadSpecificSearch(search);
    // }
    // history.replaceState("pattern","");
    this.sharedService.loadCollectionsPartial$.subscribe(value => {
      // console.log("value from shared",value)
      if(value)
      {
        this.collection = value;
        this.myCollectionsflag = true;
        this.collectionShareSaveBtn = false;
      this.loadSpecificCollection(this.collection)
      }
 
   });

    this.loadingIndicater = false;

  }

  

  loadSpecificSearch (search: any) {




    // console.log("search",search)
    var favsearchPattern = search.favsearchPattern;
    // console.log("favsearchPattern-->" + favsearchPattern);
    var cq = JSON.parse(favsearchPattern).cq;
    var aq = JSON.parse(favsearchPattern).aq;
    var query =
      JSON.parse(favsearchPattern).q != undefined
        ? JSON.parse(favsearchPattern).q
        : "";
    var coveoFacets = search.facetsCoveo;

    // console.log("cq-->" + cq);
    // console.log("q-->" + query);
    if (cq) {
      var cqSplitArr = cq.split("AND");
      // console.log("cqSplitArr====>" + cqSplitArr);

      const { updateQuery } = loadQueryActions(this.engine);
      this.engine.dispatch(updateQuery({ q: query }));

      for (let i = 0; i < cqSplitArr.length; i++) {
        var eachFacetGroup = cqSplitArr[i];
        if (eachFacetGroup.includes("OR")) {
          // console.log("eachFacetGroup inside--->" + eachFacetGroup);
          var eachFacetGroupArr = eachFacetGroup.split("OR");
          for (var j = 0; j < eachFacetGroupArr.length; j++) {
            var eachFacet = eachFacetGroupArr[j];
            var facetEachObj = eachFacet.split("==");

            // var valueOfFacet = facetEachObj?.[1].trim().slice(1).slice(0, -2);
            var valueOfFacet = facetEachObj?.[1].trim().replace(/[{("'@)}]/g, "");
            // var idOfFacet = facetEachObj?.[0].trim().slice(2)
            var idOfFacet = facetEachObj?.[0].trim().replace(/[{("'@)}]/g, "");
            // console.log("facetEachObj-->" + valueOfFacet);
            // console.log("facetEachObj-->" + idOfFacet);

            var facetValueState: FacetValueState = "selected";
            var facetValue: FacetValue = {
              value: valueOfFacet,
              state: facetValueState,
              numberOfResults: 5,
            };
            var modal: ToggleSelectFacetValueActionCreatorPayload = {
              facetId: idOfFacet,
              selection: facetValue,
            };
            var toggleSelectFacetValueaction = loadFacetSetActions(
              this.engine
            ).toggleSelectFacetValue(modal);
            this.engine.dispatch(toggleSelectFacetValueaction);
          }
        } else {
          // console.log("eachFacetGroup inside--->" + eachFacetGroup);
          var facetEachObj = eachFacetGroup.split("==");

          // var valueOfFacet = facetEachObj?.[1].trim().slice(1).slice(0, -2);
          var valueOfFacet = facetEachObj?.[1].trim().replace(/[{("'@)}]/g, "");
          // var idOfFacet = facetEachObj?.[0].trim().slice(2)
          var idOfFacet = facetEachObj?.[0].trim().replace(/[{("'@)}]/g, "");

          // console.log("facetEachObj-->" + valueOfFacet);
          // console.log("facetEachObj-->" + idOfFacet);

          var facetValueState: FacetValueState = "selected";
          var facetValue: FacetValue = {
            value: valueOfFacet,
            state: facetValueState,
            numberOfResults: 5,
          };
          var modal: ToggleSelectFacetValueActionCreatorPayload = {
            facetId: idOfFacet,
            selection: facetValue,
          };
          var toggleSelectFacetValueaction = loadFacetSetActions(
            this.engine
          ).toggleSelectFacetValue(modal);
          this.engine.dispatch(toggleSelectFacetValueaction);
        }
      }
    } else if (query) {
      const { updateQuery } = loadQueryActions(this.engine);
      this.engine.dispatch(updateQuery({ q: query }));
    }
    
    if(aq){
            aq = aq.split("AND");
            var firstPart = aq?.[0];
            var seconfPart = aq?.[1];
            try{
              firstPart = firstPart.split(">=")?.[1].trim();
            }catch(err){

            }

            try{
              // alert("seconfPart-->"+seconfPart);
              if(seconfPart.includes(">=")){
                seconfPart = seconfPart.split(">=")?.[1].trim();
              }else{
                seconfPart = seconfPart.split("<=")?.[1].trim();
              }
              
            }catch(err){
            }
            var facetValueState: FacetValueState = "selected";
            if(!firstPart){

              const today = new Date();
              const yyyy = today.getFullYear();
              let mm = today.getMonth() + 1; // Months start at 0!
              let dd = today.getDate();

              firstPart = yyyy+'/'+ mm + '/' +dd+"@00:00:00";

            }

            if(!seconfPart){
              const today = new Date();
              const yyyy = today.getFullYear();
              let mm = today.getMonth() + 1; // Months start at 0!
              let dd = today.getDate();

              seconfPart =  yyyy+'/'+ mm + '/' +dd+"@00:00:00";
            }

            var facetValueDate: DateFacetValue = {
              start: firstPart,
              end: seconfPart,
              state: facetValueState,
              numberOfResults: 1,
              endInclusive: true
            };

            var modaldate: UpdateDateFacetValuesActionCreatorPayload = {
              facetId: "contentapprovaldate_input",
              values: [facetValueDate],
            };
            var toggleSelectdateFacetValueaction = loadDateFacetSetActions(
              this.engine
            ).updateDateFacetValues(modaldate);
            this.engine.dispatch(toggleSelectdateFacetValueaction);

    }

    // const { executeSearch } = loadSearchActions(this.engine);
    // const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    // this.engine.dispatch(executeSearch(logSearchboxSubmit()));
    // this.engine.executeFirstSearch();






    ////////////////////////////////////////////////////////////////////////
    
  //   var favsearchPattern = search.favsearchPattern
  //   // var favsearchPattern = `{"q": "keytruda", "cq": "(@qddocumenttype=='Governance and Procedure')", "tab": "VVQD", "locale": "en-US", "groupBy": [{"field": "@contentapprovaldate", "sortCriteria": "AlphaAscending"}], "searchHub": "AdvanceSearch"}`;
  //   // console.log('favsearchPattern-->' + favsearchPattern)
  //   var cq = JSON.parse(favsearchPattern).cq
  //   var query = JSON.parse(favsearchPattern).q !=undefined?JSON.parse(favsearchPattern).q:"";
  //   // var coveoFacets = search.facetsCoveo

  //   // console.log('cq-->' + cq)
  //   // console.log('q-->' + query)
  //   if(cq){
  //   var cqSplitArr = cq.split('AND')
  //   // console.log('cqSplitArr====>' + cqSplitArr)

  //   const { updateQuery } = loadQueryActions(this.engine)
  //   this.engine.dispatch(updateQuery({ q: query }));

  //   for (let i = 0; i < cqSplitArr.length; i++) {
  //     var eachFacetGroup = cqSplitArr[i]
  //     if (eachFacetGroup.includes('OR')) {
  //       // console.log('eachFacetGroup inside--->' + eachFacetGroup)
  //       var eachFacetGroupArr = eachFacetGroup.split('OR')
  //       for (var j = 0; j < eachFacetGroupArr.length; j++) {
  //         var eachFacet = eachFacetGroupArr[j]
  //         var facetEachObj = eachFacet.split('==')
          

  //         // var valueOfFacet = facetEachObj?.[1].trim().slice(1).slice(0, -2);
  //         var valueOfFacet = (facetEachObj?.[1].trim()).replace(/[{("'@)}]/g,'');
  //         // var idOfFacet = facetEachObj?.[0].trim().slice(2)
  //         var idOfFacet = (facetEachObj?.[0].trim()).replace(/[{("'@)}]/g,'');
  //         // console.log('facetEachObj-->' +valueOfFacet);
  //         // console.log('facetEachObj-->' + idOfFacet);

  //         var facetValueState: FacetValueState = 'selected'
  //         var facetValue: FacetValue = {
  //           value: valueOfFacet,
  //           state: facetValueState,
  //           numberOfResults: 5
  //         }
  //         var modal: ToggleSelectFacetValueActionCreatorPayload = {
  //           facetId: idOfFacet,
  //           selection: facetValue
  //         }
  //         var toggleSelectFacetValueaction = loadFacetSetActions(
  //           this.engine
  //         ).toggleSelectFacetValue(modal)
  //         this.engine.dispatch(toggleSelectFacetValueaction)
  //       }
  //     } else {
  //       // console.log('eachFacetGroup inside else--->' + eachFacetGroup)
  //       var facetEachObj = eachFacetGroup.split('==')
        

  //       // var valueOfFacet = facetEachObj?.[1].trim().slice(1).slice(0, -2);
  //       var valueOfFacet = (facetEachObj?.[1].trim()).replace(/[{("'@)}]/g,'');
  //       // var idOfFacet = facetEachObj?.[0].trim().slice(2)
  //       var idOfFacet = (facetEachObj?.[0].trim()).replace(/[{("'@)}]/g,'');

  //       // console.log('facetEachObj-->' + valueOfFacet);
  //       // console.log('facetEachObj-->' + idOfFacet);


  //       var facetValueState: FacetValueState = 'selected'
  //       var facetValue: FacetValue = {
  //         value: valueOfFacet,
  //         state: facetValueState,
  //         numberOfResults: 5
  //       }
  //       var modal: ToggleSelectFacetValueActionCreatorPayload = {
  //         facetId: idOfFacet,
  //         selection: facetValue
  //       }
  //       var toggleSelectFacetValueaction = loadFacetSetActions(
  //         this.engine
  //       ).toggleSelectFacetValue(modal)
  //       this.engine.dispatch(toggleSelectFacetValueaction)
  //     }
      
  //   }
  // }else if(query){
  //   const { updateQuery } = loadQueryActions(this.engine);
  //   this.engine.dispatch(updateQuery({ q: query }));
  // }
  }

  resultsperPage : any;
  resultsPerPageValue : any;
  sortsettingsvalue : any;
  selectedSorting : any;
  sortingMostRecentFlag : boolean = false;
  theraupticContext : any;
  
  loadDefaultSettings()
  {

    try{
    let payload = {
      userID: this.cookieService.get('usi'),
    };
    
    // Example of the resulting payload
    // console.log(payload);
    this.collectionsService.getUSerSettings(payload).subscribe((data)=>{
      // console.log("data",data);
      if(data.body){
      let finalData = JSON.parse(data?.body);
      // console.log("finalData",finalData);
      this.theraupticContext = JSON.parse(finalData?.[0]?.THERAPEUTIC_AREA_JSON);
      // let resultsdata = JSON.parse(finalData?.[0].RESULTS_PER_PAGE);
      // this.resultsperPage = resultsdata.filter(x => x.selected == true);
      // // console.log("resultsperPage",this.resultsperPage);
      // this.resultsPerPageValue = this.resultsperPage?.[0].value;
      // // console.log("resultsPerPageValue",this.resultsPerPageValue);
      // this.sortsettingsvalue = JSON.parse(finalData?.[0].COLLECTION_SORTING);

    }

  });

}catch(err){
  // this.initializeSearchEngine();
}



}

  clearAll(){



    var facetState = this.engine.state.facetSet;
    var facetStateDate = this.engine.state.dateFacetSet;
    // console.log("facetStateDate--->"+JSON.stringify(facetStateDate));

    for (const k in facetState) {
      // console.log("k--->"+JSON.stringify(facetState[k].request.facetId));
      var toggleSelectFacetValueaction1 = loadFacetSetActions(
      this.engine
    ).deselectAllFacetValues(facetState[k].request.facetId);
    this.engine.dispatch(toggleSelectFacetValueaction1);
    }

    var toggleSelectFacetValueaction2 = loadFacetSetActions(
      this.engine
    ).deselectAllFacetValues("contentapprovaldate");
    this.engine.dispatch(toggleSelectFacetValueaction2);
    

    const action = loadAdvancedSearchQueryActions(this.engine).updateAdvancedSearchQueries({aq: '',});
    this.engine.dispatch(action);

    const actionCq = loadAdvancedSearchQueryActions(this.engine).updateAdvancedSearchQueries({cq: '',});
    this.engine.dispatch(actionCq);

    const actionDf = loadAdvancedSearchQueryActions(this.engine).updateAdvancedSearchQueries({dq: '',});
    this.engine.dispatch(actionDf);

    const { updateQuery } = loadQueryActions(this.engine);
    const { executeSearch } = loadSearchActions(this.engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    this.engine.dispatch(updateQuery({q:''}));
  
    this.engine.dispatch(executeSearch(logSearchboxSubmit()));
   // this.engine.executeFirstSearch();



    // var toggleSelectFacetValueaction1 = loadFacetSetActions(
    //   this.engine
    // ).deselectAllFacetValues("contentdocumentsubtype");
    // this.engine.dispatch(toggleSelectFacetValueaction1);
    // // const engine = this.engineService.getEngibeObj();
    // const { updateQuery } = loadQueryActions(this.engine);
    // const { executeSearch } = loadSearchActions(this.engine);
    // const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    // // this.engine.dispatch(updateQuery({q:'eample'}));
    // this.engine.dispatch(executeSearch(logSearchboxSubmit()));
    // this.engine.executeFirstSearch();

    // const facetValueState : FacetValueState ="selected";
    //     const facetValue: FacetValue = {
    //       value: 'External Use, Verbal Only',
    //       state: facetValueState,
    //       numberOfResults: 5
    //     };
    //     const modal: ToggleSelectFacetValueActionCreatorPayload = {
    //       facetId: 'contentintendeduse',
    //       selection: facetValue
    //     };
    //     const toggleSelectFacetValueaction = loadFacetSetActions(this.engine).toggleSelectFacetValue(modal);

    //     const facetValueState2 : FacetValueState ="selected";
    //     const facetValue2: FacetValue = {
    //       value: 'Scientific Content',
    //       state: facetValueState2,
    //       numberOfResults: 5
    //     };
    //     const modal2: ToggleSelectFacetValueActionCreatorPayload = {
    //       facetId: 'csn_medical_affairs_asset_type',
    //       selection: facetValue2
    //     };
    //     const toggleSelectFacetValueaction2 = loadFacetSetActions(this.engine).toggleSelectFacetValue(modal2);

    //     this.engine.dispatch(toggleSelectFacetValueaction);
    //     this.engine.dispatch(toggleSelectFacetValueaction2);  
    //     // const engine = this.engineService.getEngibeObj();
    //     const { updateQuery } = loadQueryActions(this.engine);
    //     const { executeSearch } = loadSearchActions(this.engine);
    //     const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    //     this.engine.dispatch(updateQuery({q:'eample'}));
    //     this.engine.dispatch(executeSearch(logSearchboxSubmit()));
    //     this.engine.executeFirstSearch();
  }

  getQueryPayload1() {
    const engine = this.engineService.getEngibeObj();
    const queryPayload = engine.state.query;
    const facetState = engine.state.search.response.facets;
    // const request = engine.state.recentQueries;
    // console.log('Query Payload:', JSON.stringify(queryPayload));

    for(let facet of facetState){
        // // console.log("facet-->"+JSON.stringify(facet));
        var facetValuesList = facet.values;
        if(facetValuesList.length>0){
          for(var facetValueObj of facet.values){
            if(facetValueObj.state=='selected'){
              // console.log("select facet.facetId ==> "+facet.facetId);
              // console.log("select facet.facetId ==> "+facetValueObj['value']);
            }
          } 
        }
        
        // else{
        //   facetValuesList = facet['currentValues'];
        //   if(facetValuesList.length>0){
            
        //   }
        // }
        
    }

 


    var  facetSets:any = [];
    facetSets! = engine.state.facetSet;
    // this.facet.clear();
    
    // for(let facetKey in facetSets){
    //     var value = facetSets[facetKey];
    //     var curretnVals = value['request']['currentValues'];
    //     // // console.log("curretnVals-->"+JSON.stringify(curretnVals));
    //     for(let curVal in curretnVals){
    //       // // console.log("curVal-->"+JSON.stringify(curretnVals[curVal]));
    //       // // console.log("curVal-->"+JSON.stringify(curretnVals[curVal]['state']));
    //       if(curretnVals[curVal]['state']=="selected"){
    //         // console.log("curVal-->"+facetKey+"----"+JSON.stringify(curretnVals[curVal]['value']));
    //       }
    //     }
    // }
  }

  public onResultClick(result: Result) {
    // Perform actions based on the clicked result
    // console.log('Clicked result:', result);


    // const {updateQuery} = loadQueryActions(this.engine);      
    // const actionOfQuery = updateQuery({q: query}); 
    // this.engine.dispatch(actionOfQuery);
    // this.searchInterface.executeFirstSearch();

  }



  // private val = '';
  // @Input() 
  // get label(): string {
  //   // console.log(this.atomicTextValueAttribute);
  //   if (this.atomicText) {
  //     this.val = this.atomicTextValueAttribute;
  //   }
  //   return this.val;
  // }
  // set label(v: string) {
  //   this.val = v;
  //   this.atomicTextValueAttribute = this.val;
  // }
 
 
 
  // private get atomicTextValueAttribute() {
  //   if (!this.atomicText) { 
  //     return '';
  //   }
    
  //   return this.atomicText['el'].getAttribute('value') as string;
  // }
 
  // private set atomicTextValueAttribute(v: string) {
  //   if (!this.atomicText) { 
  //     return;
  //   }
  //   this.z.runOutsideAngular(() => {
  //     this.atomicText!['el'].setAttribute('value', v); 5
  //   });
  // }




  handleClick(){
    // console.log("CALLED--");
    // console.log(this.atomicText);
    // // console.log(this.atomicText['el'].getAttribute('value') as string);
  }

  // async ngAfterViewInit(): Promise<void> {
  //   this.controller = buildController(this.engine);
  //     this.engineService.getEngibeObj().then((eng)=>{
  //       this.engine = eng;
  //       this.searchInterface.initializeWithSearchEngine(this.engine).then(() => {
  //         if(!this.searchInterface.engine) {
  //           console.error('search engine not found');
  //           return;
  //         }
  //         else {
  //           this.setContext(this.searchInterface.engine);
  //         }
  //         this.searchInterface.executeFirstSearch();
  //       });
  //     });
  // }
  ngAfterViewInit(){
    // this.loadDefaultSettings();

    // this.cookieService.set("redirect",this.router.url,1,'/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');

    this.router.events.subscribe((event)=>{
      if(event instanceof NavigationStart){
        // alert("Start called");
        // location.reload;
      }else if(event instanceof NavigationEnd){
        // alert("end called");
        // this.clearAll();
        location.reload();
      }
    })
   
      this.initializeSearchEngine();
      // if(!this.cookieService.get("cs")){
        // this.loadDefaultSettings();

      // this.cookieService.set("cs",'cmpt');
      // }
      
  }



  sharedCollection:any;
  collectionShareSaveBtn = true;
  fromPage:any;
  initializeSearchEngine(){

    
    
    this.setDefaultFacet(this.engine);

    try{
      // this.controller = buildController(this.engine);
    this.engine = this.engineService.getEngibeObj();
    this.searchInterface.initializeWithSearchEngine(this.engine).then(() => {
      if(!this.searchInterface.engine) {
        console.error('search engine not found');
        return;
      }
      else {
        this.setContext(this.searchInterface.engine);
      }
     
      // alert("CALLED-->"+JSON.stringify(this.historyState)); 
    
    if(this.historyState.pattern){
      var search = this.historyState.pattern;
      history.replaceState("pattern","");
      this.loadSpecificSearch(search);
    }else if(this.historyState.collections){
      
      var collectionObj = this.historyState.collections;
      this.sharedCollection = collectionObj.collectionObj;
      this.fromPage = collectionObj.from;
      if(this.fromPage!='settings'){
        this.collectionShareSaveBtn = collectionObj.userHasCollection; // string "false" coming as value
      }else{
        this.collectionShareSaveBtn = false;
      }
      
      history.replaceState("collections","");
      this.collection = this.sharedCollection;
      this.resultListCmp?.clearshowAllSelected();
      this.loadSpecificCollection(this.sharedCollection);
      // console.log("collectionObj--->"+JSON.stringify(collectionObj));
    }else if(history.state.savedsearch){
      var savedsearch = history.state.savedsearch;
      
      history.replaceState("savedsearch","");
      this.loadSharedsearch(savedsearch);
      // console.log("savedsearch--->"+JSON.stringify(savedsearch));
    }
      this.loadingIndicater = false; 
       this.searchInterface.executeFirstSearch();

       if(!this.cookieService.get("cs")){
        this.setDefaultFacet(this.engine);
        // this.cookieService.set("cs",'cmpt');
        this.cookieService.set("cs",'cmpt', 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
       }

    });
    this.loadingIndicater = false;

    this.loadDefaultSettings();

    }catch(err){

    }
  }


  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }


  public loadSpecificCollection(collection : any) { 
    this.cookieService.set("ucon","", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
    // console.log('collection : ' + JSON.stringify(collection));
    var collectionData = {
      'collection':collection,
    };
    // this.sharedService.sendCollectionData(collectionData);

    var ids="("
    if(collection.items.length>0){
      
      for (var item of collection.items) {
        // console.log(item.docId); // prints values: 10, 20, 30, 40
        // ids.push(item.docId)
        ids = ids + item.docId +",";
      }
      ids = ids.slice(0,-1);
      // this.mycollections.emit();
    }else{
      this.myCollectionsEmpty = true;
      this.myCollectionsflag = false;
      this.myCollectionsTitle = "Collections";
      // this.myCollectionMessage = "No documents available in this collection."
      this.myCollectionMessage =  CONFIG.RESULTS_PAGE.NO_DOCUMENTS_AVAILABLE
      this.feature = "mycollectionsmessage";

      return;
    }
    ids=ids+")";
    // console.log("ids-->"+ids);
    if(ids.length>0){
      this.myCollectionsEmpty = false;
      this.myOneDriveflag = false;
      this.myCollectionsflag = true;

      const engine = this.engineService.getEngibeObj();
      const action = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({
        aq: '(@contentsourceid=='+ids+') OR (@spouniqueid=='+ids+')',
        // aq: '(@contentsourceid=(996,3670,3700,3808,4064,7377257b-36f8-4ff2-a3d7-c3929b7385bd,316f6908-a2cd-4320-827b-b2e05c8b6f6b) OR @spouniqueid=(996,3670,3700,3808,4064,7377257b-36f8-4ff2-a3d7-c3929b7385bd,316f6908-a2cd-4320-827b-b2e05c8b6f6b))',
      });
      
      const { executeSearch } = loadSearchActions(engine);
      const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
      engine.dispatch(action);
      engine.dispatch(executeSearch(logSearchboxSubmit()));

      // engine.executeFirstSearch();

      // this.clearAll();

    }else if(ids.length==0){
      this.myCollectionsEmpty = true;
      this.myCollectionsflag = false;
      this.myCollectionsTitle = "Collections";
      // this.myCollectionMessage = "No documents available in this collection."
      this.myCollectionMessage =  CONFIG.RESULTS_PAGE.NO_DOCUMENTS_AVAILABLE
      this.feature = "mycollectionsmessage";
      return;
    }
   
  }


 loadSharedCollection(collectoionObj:any){
    var query = collectoionObj.query;
    const { updateQuery } = loadQueryActions(this.engine);
    this.engine.dispatch(updateQuery({ q: query }));
 }

 loadSharedsearch(sharedSearch:any){
  var query = sharedSearch.query;
  this.loadSpecificSearch(query);
  // const { updateQuery } = loadQueryActions(this.engine);
  // this.engine.dispatch(updateQuery({ q: query }));
}


  loadResultsOnQuery(query:string){


    const engine = this.engineService.getEngibeObj();
    const { updateQuery } = loadQueryActions(engine);
    const { executeSearch } = loadSearchActions(engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
    engine.dispatch(updateQuery({ q: query}));
    engine.dispatch(executeSearch(logSearchboxSubmit()));
    

    // const {updateQuery} = loadQueryActions(this.engine);      
    // const actionOfQuery = updateQuery({q: query}); 
    // this.engine.dispatch(actionOfQuery);
    // this.searchInterface.executeFirstSearch();

    // this.engineService.getEngibeObj().then((eng)=>{
      // this.engine = eng;
      // this.searchInterface.initializeWithSearchEngine(this.engine).then(() => {
        // if(!this.searchInterface.engine) {
        //   console.error('search engine not found');
        //   return;
        // }
        // else {


              // const {updateQuery} = loadQueryActions(this.engine);      
              // const actionOfQuery = updateQuery({q: "custom query"}); 
              // this.engine.dispatch(actionOfQuery);
              // this.searchInterface.executeFirstSearch();



        // }
        // this.searchInterface.executeFirstSearch();
      // });
    // });
  }


  saveSearch () {
    this.saveSearchPopupTitle = "Save Search";
    this.saveSearchPopupMesage = "Success";
    this.SavedSearchAddPopupShow = true;
    this.SavedSearchAddPopupShowFlag = true;
    this.SavedSearchTextMsg = "";

  }

  // SaveSearchWithPattern(pattern:any){
  //      pattern?pattern:"NameMissed";
  //      this.savedSearch.saveSearch(pattern);
  //      // console.log("pattern",pattern);
  // }

  SaveSearchWithPattern(patternObj:any){
    // console.log("SaveSearchWithPattern", patternObj);
   
    patternObj?patternObj:"NameMissed";
       this.savedSearch.saveSearch(patternObj);
       // console.log("pattern",patternObj);
  }

  closeSavedSearchPopup(){
    this.SavedSearchAddPopupShow = false;
    this.SavedSearchAddPopupShowFlag = false;
    this.SavedSearchMsg = false;
  }


  public setExpression(engine : SearchEngine) {

    const action = loadAdvancedSearchQueryActions(

      engine

    ).updateAdvancedSearchQueries({

      aq: '@source=Products',

    });

    engine.dispatch(action);

  }



  // getUserCountry(engine,callback){
  //   // return new Promise(function(resolve, reject) {
  //     let countryRes = "";
  //     var url =  "https://iapi.merck.com/internal-directory/v2/users/chilukua";
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("GET", url);
  //   xhr.setRequestHeader("Accept", "application/json");
  //   xhr.setRequestHeader("Content-Type", "application/json");
  //   xhr.setRequestHeader("X-Merck-APIKey", "vb75zNWFdbI9zUEj2yv6RgkhjFh5lsxC");
  //   xhr.onreadystatechange = function () {
  //    if (xhr.readyState === 4) {
  //       // console.log(xhr.status);
  //       // console.log(xhr.responseText);
  //       countryRes = xhr.responseText;
  //       callback(engine,countryRes);
  //       // alert(xhr.responseText);
  //       // resolve(xhr.responseText);
  //    }};
  //    var data = ``;
  //    xhr.send(data);

  //    return countryRes;
  //   // });
  // }



  public setDefaultFacet(engine : SearchEngine) {
  // console.log("country value")
    // var isid = this.authService.getIsid();
    // var ses = this.cookieService.get("ses");
    // var engine = this.engine;
    // var countryRes =  this.getUserCountry(engine,function(engine,res){


      // alert("res-->"+JSON.parse(res).country);
      // var country = JSON.parse(res).country;

      try{

        // var country = this.cookieService.get("ucon");
        // var redirect = this.cookieService.get("redirect");
      // if(country && !redirect){
      //   const countryArray = country.split(";");
      //   for(let i=0; i<countryArray.length; i++){
      //     var facetValueState: FacetValueState = 'selected'
      //     var facetValue: FacetValue = {
      //         value: countryArray[i],
      //         state: facetValueState,
      //         numberOfResults: 5
      //        }
      //     var modal: ToggleSelectFacetValueActionCreatorPayload = {
      //         // facetId: "gmsacountry",
      //         facetId: "gmsacountry-common",
      //         selection: facetValue
      //         }
      //     var toggleSelectFacetValueaction = loadFacetSetActions(engine).toggleSelectFacetValue(modal);
      //     engine.dispatch(toggleSelectFacetValueaction);
      //   }
      // }

      }catch(err){

      }

      
       
  // });

    // alert("countryRes--->"+JSON.stringify(countryRes));

  //   var facetValueState: FacetValueState = 'selected'
  //   var facetValue: FacetValue = {
  //     value: "United States",
  //     state: facetValueState,
  //     numberOfResults: 5
  //    }
  // var modal: ToggleSelectFacetValueActionCreatorPayload = {
  //   facetId: "gmsacountry",
  //   selection: facetValue
  // }
  // var toggleSelectFacetValueaction = loadFacetSetActions(
  //   this.engine
  // ).toggleSelectFacetValue(modal)
  // this.engine.dispatch(toggleSelectFacetValueaction);
  }

 

  public setContext(engine : SearchEngine) {

    const context = {}
    try{
    if(this.cookieService.get('rkymnt')){
      let rkymnt = this.cookieService.get('rkymnt');
      context['userType'] = rkymnt;
    }else if(this.cookieService.get('mrl')){
      let mrl = this.cookieService.get('mrl');
      context['userType'] = mrl;
    }else if(this.cookieService.get('hha')){
      let hha = this.cookieService.get('hha');
      context['userType'] = hha;
    }
  }catch(err){

  }

    // try{
    //   let rkymnt = this.cookieService.get('rkymnt');
    //   if(rkymnt){
    //     const actionOfSubtype = loadContextActions(engine).addContext({
    //       contextKey:'userType',
    //       contextValue: rkymnt,
    //     });
    //     engine.dispatch(actionOfSubtype);
    //   }
     
    // }catch(err){

    // }

    // try{
    //   let mrl = this.cookieService.get('mrl');
    //   if(mrl){
    //     const actionOfSubtype = loadContextActions(engine).addContext({
    //       contextKey:'userType',
    //       contextValue: mrl,
    //     });
    //     engine.dispatch(actionOfSubtype);
    //   }
      
    // }catch(err){

    // }

    // try{
    //   let hha = this.cookieService.get('hha');
    //   if(hha){
    //     const actionOfSubtype = loadContextActions(engine).addContext({
    //       contextKey:'userType',
    //       contextValue: hha,
    //     });
    //     engine.dispatch(actionOfSubtype);
    //   }
    // }catch(err){

    // }


    try{
      
      if(this.cookieService.get('hct')){
        let hct = this.cookieService.get('hct');
        context['hideContentType'] = hct;
      }
      if(this.cookieService.get('hcst')){
        let hcst = this.cookieService.get('hcst');
        context['hideContentSubType'] = hcst;
      }

    }catch(err){

    }
    
    // engine.dispatch(loadContextActions(engine).setContext(context));

    try{
      const therauptecAreas = this.theraupticContext
      .filter(item => item.selected === true)
      .map(item => item.name);    
      
      // context['therapeuticarea'] = therauptecAreas;


    //   const Context = loadContextActions(engine).addContext({
    //   contextKey:'therapeuticarea',
    //   contextValue: therauptecAreas,
    // });
    // engine.dispatch(Context);
    }catch(err){

    }

    // engine.dispatch(loadContextActions(engine).setContext(context));
    

    try{

      
    // var uc:any = "{\"country\":\"India\",\"organization\":\"Global Medical and Value Capabilities\",\"role\":\"60327178\"}";
    //  uc = JSON.parse(uc);
    try{
      // let uc = this.cryptoservice.decryptData(this.cookieService.get('uc'));
      let uc = this.cookieService.get('uc');
      context['country'] = uc['country'];
      // console.log("uc-->"+JSON.stringify(uc));
      // const action = loadContextActions(engine).addContext({
      //   contextKey:'country',
      //   contextValue: uc['country'],
      // });
      // engine.dispatch(action);
    }catch(err){

    }

    
    

   

    // try{
    //   let hct = this.cryptoservice.decryptData(this.cookieService.get('hct'));
    // const actionOfType = loadContextActions(engine).addContext({
    //   contextKey:'hideContentType',
    //   contextValue: hct,
    // });
    // engine.dispatch(actionOfType);
    // }catch(err){

    // }

    
    // try{
    //   let hcst = this.cryptoservice.decryptData(this.cookieService.get('hcst'));
    // const actionOfSubtype = loadContextActions(engine).addContext({
    //   contextKey:'hideContentSubType',
    //   contextValue: hcst,
    // });
    // engine.dispatch(actionOfSubtype);
    // }catch(err){

    // }

    try{
      let sogvd = this.cookieService.get('sogvd');
      if(sogvd){
        // context['contentType'] = sogvd;
      }
      
    // const actionOfSubtype = loadContextActions(engine).addContext({
    //   contextKey:'contentType',
    //   contextValue: sogvd,
    // });
    // engine.dispatch(actionOfSubtype);
    }catch(err){

    }

    engine.dispatch(loadContextActions(engine).setContext(context));

    

    

    

    // const action = loadContextActions(engine).addContext({

    //   contextKey: 'userGroup',

    //   contextValue: 'sales',

    // });

    
    
    

    }catch(err){
      // console.log("error-->"+err);
    }
  }


  

  floatingPopup(){
    if(this.floatingPopupFlag){
      this.floatingPopupFlag = false; 
    }else{
      this.floatingPopupFlag = true; 
    }
  }


  closeDriveEmptyPopup(){
    this.myOneDriveEmptyflag = false;
  }

  myOneDriveflag = false;
  myOneDriveEmptyflag = false;
  // myDriveMessage = "Your OneDrive has no documents to show.";
 // myDriveMessage = "There is no content downloaded from Search Navigator to your OneDrive.";
  myDriveMessage = CONFIG.RESULTS_PAGE.MY_ONEDRIVE_NO_DOCUMENTS;
  myDriveTitle = "My OneDrive";
  feature = "";
  myOneDriveClicked(myDriveDocsCount:any){
    this.feature = "mydriveempty";
    this.myCollectionsflag = false;
    if(myDriveDocsCount>0){
      this.myOneDriveflag = true;
      this.resultListCmp?.clearshowAllSelected();
    }else{
      this.myOneDriveEmptyflag = true;
    }
  }

  backToResultsFromMyOneDrive(){
    this.myOneDriveflag = false;
    this.clearAll();
  }


  myCollectionsflag = false;
  collectionMsg = false;
  collection:any;

  myCollectionsTitle = "Collections";
  m//yCollectionMessage = "No documents available in this collection."
 
  myCollectionMessage =  CONFIG.RESULTS_PAGE.NO_DOCUMENTS_AVAILABLE
  
  myCollectionsEmpty = false;
  myCollectionsClicked(newItem: any){
    this.feature = "mycollectionsmessage";
    this.collectionShareSaveBtn = false;
    // alert("newItem--->"+newItem);
    if(newItem>0){
      this.myCollectionsEmpty = false;
      this.myOneDriveflag = false;
      this.myCollectionsflag = true;
      this.resultListCmp?.clearshowAllSelected();
      if(this.dom.getElementById("bannerCollections")){
        this.dom.getElementById("bannerCollections").style.display= "";
       }
      // this.collectionMsg = true;
      this.cd.detectChanges();
    }else{
      this.myCollectionsEmpty = true;
      this.myCollectionsflag = false;
      this.myCollectionsTitle = "Collections";
      // this.myCollectionMessage = "No documents available in this collection."
      this.myCollectionMessage =  CONFIG.RESULTS_PAGE.NO_DOCUMENTS_AVAILABLE
      this.feature = "mycollectionsmessage";
      this.cd.detectChanges();
      this.clearAll();
    }
    
  }

  backToResultsFromMyMyCollections(){
    this.myCollectionsflag = false;
    this.collectionShareSaveBtn = true;
    localStorage.removeItem('myCollectionsflag');
    this.clearAll();
  }

  closeEmptyCollectionPopup(){
     this.feature = "none";
  }

  selectAllExternalUseDocs(event:any){

    let engine = this.engineService.getEngibeObj();
      var toggleSelectFacetValueaction1 = loadFacetSetActions(
        engine
    ).deselectAllFacetValues("contentintendeduse");
    engine.dispatch(toggleSelectFacetValueaction1);

    var facetsVals:any = [];
    
    if(event?.target.checked){
      facetsVals.push("External Use- Verbal and Show Only");
      facetsVals.push("External Use- Verbal Only");
      facetsVals.push("External Use- Verbal- Show and Distribution in Part");
      facetsVals.push("External Use- Verbal- Show- and Distribution in Part or Totality");
    }else{
      // facetsVals.push("Internal Use Only");
    }


    for (let i = 0; i < facetsVals.length; i++) {
      var facetValueState: FacetValueState = 'selected'
      var facetValue: FacetValue = {
        value: facetsVals[i],
        state: facetValueState,
        numberOfResults: 5
       }
    var modal: ToggleSelectFacetValueActionCreatorPayload = {
      facetId: "contentintendeduse",
      selection: facetValue
    }
    var toggleSelectFacetValueaction = loadFacetSetActions(
      this.engine
    ).toggleSelectFacetValue(modal)
  var toggleSelectFacetValueaction = loadFacetSetActions(
    this.engine
  ).toggleSelectFacetValue(modal);
  this.engine.dispatch(toggleSelectFacetValueaction);
    }
    

    // var facetValueState: FacetValueState = 'selected'
    //     var facetValue: FacetValue = {
    //       value: "External Use, Verbal and Show Only",
    //       state: facetValueState,
    //       numberOfResults: 5
    //      }
    //   var modal: ToggleSelectFacetValueActionCreatorPayload = {
    //     facetId: "contentintendeduse",
    //     selection: facetValue
    //   }
    //   var toggleSelectFacetValueaction = loadFacetSetActions(
    //     this.engine
    //   ).toggleSelectFacetValue(modal)
    // var toggleSelectFacetValueaction = loadFacetSetActions(
    //   this.engine
    // ).toggleSelectFacetValue(modal);
    // this.engine.dispatch(toggleSelectFacetValueaction);

    const { executeSearch } = loadSearchActions(this.engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
    this.engine.dispatch(executeSearch(logSearchboxSubmit()));
   // this.engine.executeFirstSearch();
  }

  freezeResults(){


    var search = `{"q": "keytruda", "cq": "(@qddocumenttype=='Governance and Procedure')", "tab": "VVQD", "locale": "en-US", "groupBy": [{"field": "@contentapprovaldate", "sortCriteria": "AlphaAscending"}], "searchHub": "AdvanceSearch"}`;

    // console.log("search",search);
    var favsearchPattern = search;
    // console.log("favsearchPattern-->" + favsearchPattern);
    var cq = JSON.parse(favsearchPattern).cq;
    var aq = JSON.parse(favsearchPattern).aq;
    var query =
      JSON.parse(favsearchPattern).q != undefined
        ? JSON.parse(favsearchPattern).q
        : "";

    // console.log("cq-->" + cq);
    // console.log("q-->" + query);
    if (cq) {
      var cqSplitArr = cq.split("AND");
      // console.log("cqSplitArr====>" + cqSplitArr);

      const { updateQuery } = loadQueryActions(this.engine);
      // this.engine.dispatch(updateQuery({ q: query }));

      // for (let i = 0; i < cqSplitArr.length; i++) {
      //   var eachFacetGroup = cqSplitArr[i];
      //   if (eachFacetGroup.includes("OR")) {
      //     // console.log("eachFacetGroup inside--->" + eachFacetGroup);
      //     var eachFacetGroupArr = eachFacetGroup.split("OR");
      //     for (var j = 0; j < eachFacetGroupArr.length; j++) {
      //       var eachFacet = eachFacetGroupArr[j];
      //       var facetEachObj = eachFacet.split("==");

      //       // var valueOfFacet = facetEachObj?.[1].trim().slice(1).slice(0, -2);
      //       var valueOfFacet = facetEachObj?.[1].trim().replace(/[{("'@)}]/g, "");
      //       // var idOfFacet = facetEachObj?.[0].trim().slice(2)
      //       var idOfFacet = facetEachObj?.[0].trim().replace(/[{("'@)}]/g, "");
      //       // console.log("facetEachObj-->" + valueOfFacet);
      //       // console.log("facetEachObj-->" + idOfFacet);

      //       var facetValueState: FacetValueState = "selected";
      //       var facetValue: FacetValue = {
      //         value: valueOfFacet,
      //         state: facetValueState,
      //         numberOfResults: 5,
      //       };
      //       var modal: ToggleSelectFacetValueActionCreatorPayload = {
      //         facetId: idOfFacet,
      //         selection: facetValue,
      //       };
      //       var toggleSelectFacetValueaction = loadFacetSetActions(
      //         this.engine
      //       ).toggleSelectFacetValue(modal);
      //       this.engine.dispatch(toggleSelectFacetValueaction);
      //     }
      //   } else {
      //     // console.log("eachFacetGroup inside--->" + eachFacetGroup);
      //     var facetEachObj = eachFacetGroup.split("==");

      //     // var valueOfFacet = facetEachObj?.[1].trim().slice(1).slice(0, -2);
      //     var valueOfFacet = facetEachObj?.[1].trim().replace(/[{("'@)}]/g, "");
      //     // var idOfFacet = facetEachObj?.[0].trim().slice(2)
      //     var idOfFacet = facetEachObj?.[0].trim().replace(/[{("'@)}]/g, "");

      //     // console.log("facetEachObj-->" + valueOfFacet);
      //     // console.log("facetEachObj-->" + idOfFacet);

      //     var facetValueState: FacetValueState = "selected";
      //     var facetValue: FacetValue = {
      //       value: valueOfFacet,
      //       state: facetValueState,
      //       numberOfResults: 5,
      //     };
      //     var modal: ToggleSelectFacetValueActionCreatorPayload = {
      //       facetId: idOfFacet,
      //       selection: facetValue,
      //     };
      //     var toggleSelectFacetValueaction = loadFacetSetActions(
      //       this.engine
      //     ).toggleSelectFacetValue(modal);
      //     this.engine.dispatch(toggleSelectFacetValueaction);
      //   }
      // }
    } else if (query) {
      const { updateQuery } = loadQueryActions(this.engine);
      // this.engine.dispatch(updateQuery({ q: query }));
    }


    const engine = this.engineService.getEngibeObj();
      const action = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({
        aq: decodeURIComponent(cq)
        // aq: '(@contentsourceid=(996,3670,3700,3808,4064,7377257b-36f8-4ff2-a3d7-c3929b7385bd,316f6908-a2cd-4320-827b-b2e05c8b6f6b) OR @spouniqueid=(996,3670,3700,3808,4064,7377257b-36f8-4ff2-a3d7-c3929b7385bd,316f6908-a2cd-4320-827b-b2e05c8b6f6b))',
      });
      
      const { executeSearch } = loadSearchActions(engine);
      const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
      engine.dispatch(action);
      engine.dispatch(executeSearch(logSearchboxSubmit()));


    // let engine = this.engineService.getEngibeObj();
    // const { executeSearch } = loadSearchActions(engine);
    //   const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
    //   // engine.dispatch(action);
    //   engine.dispatch(executeSearch(logSearchboxSubmit()));




    // let engine = this.engineService.getEngibeObj();
    //   var toggleSelectFacetValueaction1 = loadFacetSetActions(
    //     engine
    // ).updateFreezeCurrentValues("contentintendeduse");
    // engine.dispatch(toggleSelectFacetValueaction1);


  //   var modal: UpdateFreezeCurrentValuesActionCreatorPayload = {
  //     facetId: "contentintendeduse",
  //     freezeCurrentValues: true
  //   }

    
  //   var toggleSelectFacetValueaction = loadFacetSetActions(
  //     this.engine
  //   ).updateFreezeCurrentValues(modal)
  // // var toggleSelectFacetValueaction = loadFacetSetActions(
  // //   this.engine
  // // ).toggleSelectFacetValue(modal);
  // this.engine.dispatch(toggleSelectFacetValueaction);

  // const { executeSearch } = loadSearchActions(this.engine);
  //   const { logSearchboxSubmit } = loadSearchAnalyticsActions(this.engine);
  //   this.engine.dispatch(executeSearch(logSearchboxSubmit()));
  //   this.engine.executeFirstSearch();


  }

  // exportToExcel(){
    
  // }


  changeLabelOfFacet(id, tooltip){
    var parentElement = document.getElementById(id);
    var shadowRoot = parentElement?.shadowRoot;
    var btn = shadowRoot!.querySelector('[part="label-button"]');
    if(btn){
      btn!.setAttribute("title",tooltip);
    }
  }

  // custom Events 

  async customEventsSaveSearch()
  {
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      var resp = await this.authService.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
           if(resp == "true"){
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Save-Search'},'Save-Search');

  }
  
  customEventsExportToExcel()
  {
  this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Export-Excel'},'Export-Excel');
  }

  customEventRenameCollections(rename)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Rename Collection', Title : rename},'MyCollections-Rename');

  }

  customEventDeleteCollections(rename)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Delete Collection', Title : rename},'MyCollections-Delete');

  }

  customEventSaveCollections()
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Save Collection'},'MyCollections-Save');

  }
  customEventShareCollections(collectionName:any)
{
  this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click',Details : collectionName},'MyCollections-Share');
}
  customEventsMerckLogo()

  {

    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click on Merck logo'},'Result-page');

 

  }

  externalUse : any;
  private loadConfiguration(): void {
    this.configService.loadConfig().subscribe(
      (data) => {
        this.configService.setConfig(data);
        console.log('Configuration loaded:', data);
  
        // Modify the content to include <br> for line break
        const htmlContent = data.externalUse.replace(
          'designated system of record.',
          'designated system of record.<br>'
        );
  
        // Safely bypass security and assign to disclosureData
       // this.externalUse = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  
        this.externalUse = this.sanitizer.bypassSecurityTrustHtml(`
        <span>${data.disclosureStatement}</span>
      `);
        console.log(this.externalUse);
      },
      (error) => {
        console.error('Error loading configuration:', error);
      }
    );
  }

}



// function justCall() {
//   let that:any = this.engine;
//   const facetState = that.engine.state.search.response.facets;
//   alert('Query Payload:'+JSON.stringify(facetState));
//   // throw new Error('Function not implemented.');
// }

