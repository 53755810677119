import { Component } from '@angular/core';

@Component({
  selector: 'app-data-source-section',
  templateUrl: './data-source-section.component.html',
  styleUrls: ['./data-source-section.component.css']
})
export class DataSourceSectionComponent {
  dataSources = [
    {
      title:"Veeva Vault MedComms",
      subtitle : "Content management system and official repository for GMSA and CORE based content. MedComms enables content review and approval, storage, management, and distribution. It supports unique content types, lifecycles, and workflows specific to the needs of Medical Affairs."
    },
    {
      title:"Veeva Vault QualityDocs",
      subtitle : "A cloud-based regulated document control and management solution for quality, manufacturing, validation, and other GxP-controlled content. MRL uses QualityDocs as an official document repository for Policies, SOPs, and Supporting Procedural Documents."
    },
    {
      title:"GMSA News",
      subtitle : "Collection of GMSA SharePoint sites that contain published news, updates, priorities, communications, and initiatives."
    },

    // {
    //   title:"Value Evidence Synthesis & Communication ",
    //   subtitle : "A scientific content management solution tailored for global medical affairs teams"
    // },
    // {
    //   title:"Value Evidence Complimentary",
    //   subtitle : "Global Medical & Scientific Affairs Updates, Priorities, Communications and Initiatives"
    // },
    // {
    //   title:"Model Repository",
    //   subtitle : "Accelerate review and approval processes and facilitate the sharing of SOPs and other GxP documents among employees and partners"
    // }
  ]
  
  ngonInit(){
    this.dataSources;
    // console.log("CardsResponse",this.dataSources);
  }
}
