<div style="height: 100vh; display: flex; flex-direction: column; font-family: invention;z-index: 1;">

   <app-header>
   </app-header>

   <div style="display: flex;
   flex-direction: row;
   justify-content: space-around;">
      <div class="breadcrum" style="display: flex;" (click)="backToResults()">
         <!-- <span class="material-icons outlined">arrow_back</span> -->
         <div
            style="line-height: 24px;margin-left: -23px; font-weight: 400; font-weight: 400; text-decoration: underline;">
            Back to results</div>
      </div>
      <div style="     flex-basis: 80%;
       text-align: center;
       line-height: 3rem;
       font-weight: 700;"><span style="font-size: 16px;">User Settings</span></div>
   </div>

   <div style="height: 100vh;overflow: auto;z-index: 1;">

      <!-- <div class="breadcrum" (click)="backToResults()">Back to results</div> -->
      <div class="season_tabs">
         <div *ngIf="searchSettingsFeature" class="season_tab">
            <input type="radio" (click)="tabChange(1)" id="tab-1" name="tab-group-1" [checked]="userSettingsTab">
            <label for="tab-1" style="cursor: pointer;
             display: flex;
             align-items: center;
             justify-content: flex-start;">
               <span class="material-icons outlined">
                  settings
               </span>
               <span style="padding-left: 10px; margin-top: 4px;">Search Settings</span>
            </label>

            <div class="season_content" style="overflow: auto;">
               <div>
                  <span class="titleMain">Search Settings</span>
                  <div class="" style="margin-top: 1rem;">
                     <span>Therapeutic Area</span>
                     <br>
                     <span class="sub">Select TA that you are interested in</span>
                  </div>
                  <div class="search">
                     <div class="checkbox-row" *ngFor="let option of searchOptions; let i = index">
                         <div style="display: flex; align-items: flex-start; margin-right: 6rem;">
                             <input type="checkbox" [(ngModel)]="selectedOptions[i]">
                             <span>{{ option }}</span>
                         </div>
                     </div>
                 </div>
                 
                 
                   
                   
                   
                   
                  <div class="divider"></div>
                  <div *ngIf="snackBarFlag" class="snackBar">Settings updated successfully.</div>
                  <div *ngIf="snackBarFlagCancel" class="snackBar">Settings cancelled successfully.</div>


               </div>
               <div>
                  <span class="title">Results per Page</span>
                  <div style="margin-left: -1rem;">
                     <label *ngFor="let pageNumber of pageNumbers">
                       <input style="display:inline;" class="radio" type="radio" [(ngModel)]="selectedPageNumber"
                              [value]="pageNumber">
                       {{ pageNumber }}
                     </label>
                   </div>
                   
                  <div class="divider"></div>
               </div>

               <div>
                  <span class="title">Collections sorting on Home page</span>
                  <div style="display: flex; justify-content: space-between; align-items: center; margin-left: -1rem;">
                     <ng-container *ngFor="let sortingOption of sortingList">
                        <span>
                          <label style="width: 400px !important;">
                            <input class="radio" style="display: inline;" type="radio" name="sorting" [(ngModel)]="selectedSorting" [value]="sortingOption.toLowerCase()">
                            {{ sortingOption | titlecase }}
                            <br>
                            <span *ngIf="sortingOption.toLowerCase() === 'most recent'">The collections that have been added or modified most recently, in chronological order. This will help you stay up-to-date with the latest collections in your list.</span>
                            <span *ngIf="sortingOption.toLowerCase() === 'most used'">The Collections that you clicked on the most. This will help you quickly access popular collections that you have found valuable.</span>
                          </label>
                        </span>
                      </ng-container>                      
                   </div>
                   
               </div>
               <div class="buttonContainer">
                  <button (click)="cancel()" class="cancel">Cancel</button>
                  <button (click)="updateSearchSettings()" class="save">Save</button>
   
               </div>
            </div>
           </div> 
            <div class="season_tab">
               <input type="radio" (click)="tabChange(2)" id="tab-2" name="tab-group-1" [checked]="collectionsTab">


               <label for="tab-2" style="cursor: pointer;
             display: flex;
             align-items: center;
             justify-content: flex-start;">
                  <span class="material-icons outlined">
                     bookmark_border
                  </span>
                  <span style="padding-left: 10px; margin-top: 4px;">Collections</span></label>


               <div class="season_content" style="overflow: auto;">
                  <select class="sort-selectbox" (change)="getCollectionsSorted($event.target.value)">
                     <!-- <option class="options" value="">Collections Sort</option> -->
                     <option class="options" value="ascending">Alphbetically A to Z</option>
                     <option class="options" value="descending">Alphbetically Z to A</option>
                     <option class="options" value="date-ascending">Oldest to Newest</option>
                     <option class="options" value="date-descending">Newest to Oldest</option>
                  </select>
                  <!-- <span>content 2</span> -->
                  <!-- <div class="breadcrum" (click)="backToResults()">Back to results</div> -->
                  <span><app-collections #CollectionsComponent [loadBigListOfCollections]="collectionsTab"
                        [allCollections]="collectionslist" [sortOrder]="sortSelected"></app-collections></span>
               </div>
            </div>
            <div class="season_tab">
               <!-- <input type="radio" id="tab-3" name="tab-group-1"> -->
               <input type="radio" (click)="tabChange(3)" id="tab-3" name="tab-group-1" [checked]="savedSearchesTab">


               <label for="tab-3" style="cursor: pointer;
             display: flex;
             align-items: center;
             justify-content: flex-start;"><span class="material-icons outlined sync-icon">star_outline</span><span
                     style="padding-left: 10px;">Saved Searches</span></label>

                      


               <div class="season_content" style="overflow: auto;">

                  <!-- <div class="season_content" style="overflow: auto;">
                     <select class="sort-selectbox" (change)="getSavedSearchessSorted($event.target.value)">
                        <option class="options" value="">Collections Sort</option>
                        <option class="options" value="ascending">Alphbetically A to Z</option>
                        <option class="options" value="descending">Alphbetically Z to A</option>
                        <option class="options" value="date-ascending">Newest to Oldest</option>
                        <option class="options" value="date-descending">Oldest to Newest</option>
                     </select>
                     <span><app-collections #CollectionsComponent [loadBigListOfCollections]="collectionsTab"
                           [allCollections]="collectionslist"></app-collections></span>
                  </div> -->

                  <!-- <div class="breadcrum" (click)="backToResults()">Back to results</div> -->


                  <select class="sort-selectbox" (change)="getSavedSearchessSorted($event.target.value)">
                     <!-- <option class="options" value="">Saved Searches Sort</option> -->
                     <option class="options" value="ascending">Alphbetically A to Z</option>
                     <option class="options" value="descending">Alphbetically Z to A</option>
                     <option class="options" value="date-ascending">Oldest to Newest</option>
                     <option class="options" value="date-descending">Newest to Oldest</option>
                  </select>    

                  <span><app-savedsearch [bigListPopup]="savedSearchesTab"
                        [allSavedSearches]="savedSearchesList" [sortOrder]="sortSelected"></app-savedsearch></span>
               </div>
            </div>
     
         </div>
      
         <div style="height: 6vh;margin-bottom: 2rem;">
            <!-- <app-results-page-footer></app-results-page-footer> -->
            <app-footer></app-footer>
         </div>
      </div>
      <!-- <app-results-page-footer></app-results-page-footer> -->
      <!-- <app-savedsearch></app-savedsearch> -->
