import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class CookieServiceWrapper {

  constructor(
    private cookieService: CookieService,
    private cryptoService: CryptoService
  ) {}

  set(key: string, value: any, expireDays?: number, path?: string, domain?: string, secure?: boolean, sameSite?: 'Strict' | 'Lax' | 'None'): void {
    const encryptedValue = this.cryptoService.encryptData(value);
    this.cookieService.set(key, encryptedValue, expireDays, path, domain, secure, sameSite);
  }

  get(key: string): any {
    const encryptedValue = this.cookieService.get(key);
    if (encryptedValue) {
      const decryptedValue = this.cryptoService.decryptData(encryptedValue);
      return decryptedValue;
    }
    return null;
  }

  delete(key: string, path?: string, domain?: string, secure?: boolean, sameSite?: 'Strict' | 'Lax' | 'None'): void {
    this.cookieService.delete(key, path, domain, secure, sameSite);
  }

}
