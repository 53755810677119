import { Component } from '@angular/core';

@Component({
  selector: 'app-results-page-footer',
  templateUrl: './results-page-footer.component.html',
  styleUrls: ['./results-page-footer.component.css']
})
export class ResultsPageFooterComponent {

}
