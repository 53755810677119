import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { CollectionsService } from '../collections.service';
import { CONFIG } from 'src/environments/environment.development';
import { AuthService } from '../auth.service';
import { DOCUMENT } from '@angular/common';
import { SharedService } from '../shared.service';
import { EngineService } from '../engine.service';
import { Result, loadAdvancedSearchQueryActions, loadFacetSetActions, loadQueryActions, loadSearchActions, loadSearchAnalyticsActions } from '@coveo/headless';
import { Route, Router } from '@angular/router';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import { ENVIRONMENT } from 'src/environments/environment.development';

@Component({
  selector: 'app-recomendation-section',
  templateUrl: './recomendation-section.component.html',
  styleUrls: ['./recomendation-section.component.css']
})
export class RecomendationSectionComponent implements OnInit {

  constructor(    private collectionsService:CollectionsService,     private Auth:AuthService, 
    private sharedService : SharedService,    private engineService:EngineService, private route : Router, private cookieService: CookieServiceWrapper

    )
    {
      
    }
    private dom:any = inject(DOCUMENT);
    currentCollection : any;
    @Output() close = new EventEmitter<void>();
    @Output("mycollections") mycollections: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.userId = this.Auth.getUserId();

    this.loadDefaultSettings()

    // this.CardsResponse;
    // // console.log("CardsResponse",this.CardsResponse);
    }

// collectionResponse = [
//   {
//   title : "Keytruda head & neck (6)"
//   },
//   {
//     title : "Keytruda head & neck (6)"
//   },
//   {
//     title : "Keytruda head & neck (6)"
//     },
//     {
//       title : "Keytruda head & neck (6)"
//     },
//     {
//       title : "Keytruda head & neck (6)"
//     },
//     {
//       title : "Keytruda head & neck (6)"
//     },
//     {
//       title : "Keytruda head & neck (6)"
//     }
// ]
// CardsResponse = [
//   {
//     title : "2020-05-06 Nursing Manual Home Health Pembro Template",
//     subTitle : "Nursing Manual Home Health Pembro Home Nursing Manual",
//     source : "Veeva Vault QualityDocs"
//   },
//   {
//     title : "2020-05-06 Nursing Manual Home Health Pembro Template",
//     subTitle : "Nursing Manual Home Health Pembro Home Nursing Manual",
//     source : "Veeva Vault QualityDocs"
//   },
//   {
//     title : "2020-05-06 Nursing Manual Home Health Pembro Template",
//     subTitle : "Nursing Manual Home Health Pembro Home Nursing Manual",
//     source : "Veeva Vault QualityDocs"
//   },
//   {
//     title : "2020-05-06 Nursing Manual Home Health Pembro Template",
//     subTitle : "Nursing Manual Home Health Pembro Home Nursing Manual",
//     source : "Veeva Vault QualityDocs"
//   }
// ]
limitedCollections:any = [];
  loadingIndicater: boolean | undefined;
  ngZone: any;
  sourceFlag: any;
  userId: any;
  collectionslist: any;
  noCollectionFlag: boolean | undefined;
  mainCollectionItemFlag: boolean | undefined;
  noCollectionMessage: string | undefined;
  allCollections: any;
  noRecordsMeg: any;
  collectionRecordsCount : any;
  limitedCollectionsArray : any;
  collectionMainFlag : any;
loadAllCollections(){
  this.limitedCollections = [];
  this.loadingIndicater = true;
  // this.ngZone.run(() =>{
  var tab = this.sourceFlag;
  if(tab == "GMSA News"){
    tab = "SP"
  }
  else if(tab == "Veeva Vault MedComms"){
    tab = "VVMC"
  }
  // console.log("tab",tab);
  var data = {
        "collectionId": "",
        "userId": this.userId,
        "tab": tab
    }
    this.collectionsService.getAllCollections(data).subscribe((collections)=>{
    this.collectionslist = collections.collectionlist;

    // console.log("collectionslist", this.collectionslist);

    if(this.collectionslist?.length == 0){
       this.noCollectionFlag = true;
       this.mainCollectionItemFlag = true;
       this.noCollectionMessage = "No Collection Available"
    }
    // console.log("noCollectionFlag", this.collectionMainFlag);

    let count = 0
    try{
      for(let i=0; i<this.collectionslist?.length; i++){
        this.limitedCollections.push(this.collectionslist[i]);
        count = count+1;
        if(count>=5){
          break;
        }
      }
    }catch(err){

    }
    this.limitedCollections = new Set(this.limitedCollections);
    this.allCollections = collections.collectionlist;

    if(!this.allCollections ){
      this.noRecordsMeg = CONFIG.COLLECTIONS.NO_RECORDS_AVAILABLE;
    }
        // Sort based on flags
        this.limitedCollectionsArray = Array.from(this.collectionslist);

        if (this.mostRecent) {
          this.limitedCollectionsArray.sort((a: any, b: any) => {
              return new Date(b.date).getTime() - new Date(a.date).getTime();
          });
          // console.log("Sorted by most recent:", this.limitedCollectionsArray.slice(0, 5));
          this.limitedCollections =  this.limitedCollectionsArray.slice(0, 5)// Show only the first 5 items
      } else if (this.mostUsed) {
          this.limitedCollectionsArray.sort((a: any, b: any) => {
              return b.nooftimesused - a.nooftimesused;
          });
          // console.log("Sorted by most used:", this.limitedCollectionsArray.slice(0, 5));
          this.limitedCollections =  this.limitedCollectionsArray.slice(0, 5)// Show only the first 5 items
          // Show only the first 5 items
      }
      
      
      // console.log("limitedCollections", this.limitedCollections);
   })
  // }
  // )

  this.loadingIndicater = false;

}
  

  public loadSpecificCollection(collection : any, tab:any) { 
    this.cookieService.set("ucon","", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');
    this.updateCollectionsUsed(collection.id);

    this.sharedService.setLoadCollectionsPartial(collection);
    this.route.navigate(['/'])
  }

  clearAll(){
    const engine = this.engineService.getEngibeObj();
    
    var facetState = engine.state.facetSet;
      var facetStateDate = engine.state.dateFacetSet;
      // console.log("facetState--->"+JSON.stringify(facetState));
  
      for (const k in facetState) {
        // console.log("k--->"+JSON.stringify(facetState[k].request.facetId));
        var toggleSelectFacetValueaction1 = loadFacetSetActions(
        engine
      ).deselectAllFacetValues(facetState[k].request.facetId);
      engine.dispatch(toggleSelectFacetValueaction1);
      }
  
      var toggleSelectFacetValueaction2 = loadFacetSetActions(
        engine
      ).deselectAllFacetValues("contentapprovaldate_input");
      engine.dispatch(toggleSelectFacetValueaction2);
  
  
      const action = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({aq: '',});
      engine.dispatch(action);
  
      const actionDf = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({dq: '',});
      engine.dispatch(actionDf);
  
      const actionlq = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({lq: '',});
      engine.dispatch(actionlq);
  
      const actioncq = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({cq: '',});
      engine.dispatch(actioncq);
  
      const { updateQuery } = loadQueryActions(engine);
      const { executeSearch } = loadSearchActions(engine);
      const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
      engine.dispatch(updateQuery({q:''}));
    
      engine.dispatch(executeSearch(logSearchboxSubmit()));
      engine.executeFirstSearch();
  
  }

  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
  }
  
  navigateToSettings() {
    this.route.navigate(['/settings'], { queryParams: { tab: 2 } });
  }

  mostUsed : boolean = false;
  mostRecent : boolean = false;
  selectedPageNumber : any;

  updateCollectionsUsed(item:any)
  {
    let payload = {
      "collectionID" : item
    }
    this.collectionsService.updateCollectionsUsed(payload).subscribe(async (data) => {
      // console.log("data", data);
  
  });
  }


  loadDefaultSettings() {
    let payload = {
        userID: this.userId,
    };

    // Example of the resulting payload
    // console.log(payload);
    this.collectionsService.getUSerSettings(payload).subscribe(async (data) => {
        // console.log("data", data);
        let finalData = await JSON.parse(data.body);
        const sorting = finalData?.[0].COLLECTION_SORTING;
        let selectedSorting = JSON.parse(sorting);
        // console.log("sorting", selectedSorting);

        // Update flags based on received data
        selectedSorting.forEach(item => {
            if (item.label === "most recent") {
                this.mostRecent = item.selected;
            } else if (item.label === "most used") {
                this.mostUsed = item.selected;
            }
        });
        const resultsperpagebyuser = finalData?.[0].RESULTS_PER_PAGE;
        let selectedOresultsperpagebyUser = JSON.parse(resultsperpagebyuser);
        let getPageNumber = selectedOresultsperpagebyUser.filter(x => x.selected == true);
        // console.log("getPageNumber",getPageNumber);
        this.selectedPageNumber =  getPageNumber?.[0].value;
        // console.log("selectedPageNumber",this.selectedPageNumber);
        localStorage.setItem('resultsperpage',this.selectedPageNumber)

        this.loadAllCollections();

        // console.log("mostRecent", this.mostRecent);
        // console.log("mostUsed", this.mostUsed);
    });
}


}
