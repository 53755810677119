<!-- <span (click)="addTocollections(result,'remove')" *ngIf="checkCollectionStatus(result);else unfilled"  class="buttonsStyle"><img style="width: 8%;margin-right:5px" src={{collection_icon_filled}}>Remove from Collections</span>
<ng-template #unfilled><span (click)="addTocollections(result,'add')" class="buttonsStyle"><img style="width: 8%;margin-right:5px" src={{collection_icon_unfilled}}>Add to Collections</span></ng-template>
<span class="vertical-border"></span> -->


<div *ngIf="loadCollectionsPartial" >
  
  <div class="overlay" (click)="loadCollectionsPartial==false"></div>
  <div class="popup-collections">
    <div class="content">
      <div class="popup-title" style="    
      /* margin-bottom: 1rem; */
      /* margin-bottom: 1rem; */
      border-bottom: 1px solid #00857c;
      padding: 7px;
      ">Collections</div>
    <div style="    height: 13.5rem;
    overflow: auto;">


     
    <div *ngIf="collectionslist; else noItems">
  
      <div *ngIf="loadingIndicater">
        Loading...
      </div>

      <div *ngFor="let collectionObj of limitedCollections">
        <div (click)="loadSpecificCollection(collectionObj,'');customEventLoadSpecificCollection(collectionObj.name)" class="smalllist-item">
          <!-- <ng-container *ngIf = "noCollectionFlag">
            <span>{{noCollectionMessage}}</span>
          </ng-container> -->
          <ng-container *ngIf = "!noCollectionFlag">
            <span>{{collectionObj.name}}</span>
          </ng-container>
        </div>
      </div>
    </div>


      


      <!-- <ng-template #noItems> -->
        <div *ngIf = "noCollectionFlag" style="    display: flex;
        height: 100%;
        justify-content: center;
        text-align: center;
        align-items: center; font-family: invention; font-size: 14px;font-weight:400">No collections available here.</div>



      <!-- </ng-template> -->

      
    </div>  
    
    <!-- <div (click)="loadSettingsPage()" style="display: flex; padding: 5px 12px 5px 12px; justify-content: space-between; border: 1px solid #e5e8e8;">
      <span style="text-decoration: underline; color: teal;">See all</span>
  </div> -->
    </div>
    <div>
      <span style="text-align: left;
      /* margin-left: 16px; */
      justify-content: left;">
      <div (click)="loadSettingsPage();customEventsSeeAllCollections()" style="display: flex; padding: 5px 12px 5px 12px; justify-content: space-between; border-top: 1px solid #e5e8e8; cursor: pointer;">
        <span class="seeall">See all </span> <span class="material-icons outlined arrow-left">
          navigate_next
          </span>
      </div>
      </span>
      <!-- <button style="color:teal !important"  class="docFavCancleBtn" (click)="loadCollectionsPartial==false">Cancel</button> -->
    </div>
  </div>
  
</div>


<div *ngIf="loadBigListOfCollections" >
  <div style="margin-bottom: 2rem;">
    <span class="headingMain">My Collections</span>
  </div>
  <div class="subHeadingsMain" style="display: flex;border-bottom:1px solid #E2E2E2;margin-bottom:1rem; gap:10px">
    <!-- <div style="flex-basis: 5%;cursor: pointer;"><input id="mainCheckBox" (click)="mainCheckBox()" type="checkbox"></div> -->
    <div style="flex-basis: 5%;cursor: pointer;"></div>
    <div style="flex-basis: 40%;cursor: pointer;">Collection Name</div>
    <div style="flex-basis: 60%;">Description</div>
    <div style="flex-basis: 25%;">Last Modified</div>
    <!-- <div style="flex-basis: 25%;" >Last Modified</div> -->
    <div style="flex-basis: 25%;">Actions</div>
  </div>



  <!-- <div *ngIf="allCollections; else noItems"> -->

    <div *ngIf="loadingIndicater">
      Loading...
    </div>

    <div *ngFor="let collection of allCollections">
      <div>
          <div style="display: flex; color: teal; font-size: 14px; font-weight: 400; margin-bottom: 1.2rem; gap:10px;">
            <div class="bigListCollecDate" style="flex-basis: 5%;"><input (click)="addRemoveToMultiList(collection)"  class="multiselect" type="checkbox" id="{{collection.id}}"></div>
              <div [title]="collection.name" class="bigListCollectionName" style="flex-basis: 40%; cursor: pointer; word-break: break-all;" (click)="loadCollectionInResultsPage(collection);customEventSpecificCollection(collection)">
                {{ collection.name.length > 25 ? (collection.name | slice:0:25) + '...' : collection.name }}
                <!-- {{collection.name}} -->

                <span>({{ collection.items.length }})</span>

              </div>
              <div class="bigListCollecDate" style="flex-basis: 60%; word-break: break-all;">{{collection.description}}</div>
              <div class="bigListCollecDate" style="flex-basis: 25%;">{{collection.date|date}}</div>
              <div class="bigListCollecaction" style="flex-basis: 25%;display: flex; justify-content: space-between;">
                <span *ngIf="this.selectionList.length == 0" (click)="renameCollection(collection);customEventRenameCollection(collection)" class="action-button">Edit</span>
                <span *ngIf="this.selectionList.length > 0" style="color:darkgray; cursor: not-allowed;">Edit</span>
                <span (click)="shareCollection(collection);customEventShareCollection(collection)" class="action-button">Share</span>
                <span (click)="deleteCollectionWindow(collection);customEventDeleteCollection(collection)" class="action-button red">Delete</span>
              </div>
              
          </div>
      </div>
  </div>

  
  <div *ngIf="!allCollections.length" style="     display: flex;
  height: 400px;
  justify-content: center;
  text-align: center;
  align-items: center; font-family: invention; font-size: 14px;">{{noCollectionsAvailableText}}</div>

  <!-- </div> -->

  <!-- <ng-template #noItems> -->
    <!-- <div *ngIf="noCollectionFlag" style="    display: flex;
    height: 400px;
    justify-content: center;
    text-align: center;
    align-items: center; font-family: invention; font-size: 14px;font-weight:400">No collections available</div> -->
  <!-- </ng-template> -->


 
  </div>


  <div *ngIf="CollectionRenamePopupShow">
    <div id="popup1" class="overlay" (click)="closeRenamePopup()"></div>
        <div class="popup">
            <!-- <a class="close" href="javascript:void(0);" (click)="closeRenamePopup()">&times;</a> -->
            <div class="content">
              <div style="display: flex;">
                <!-- <div style="flex-basis: 10%;"><div class="oneDriveInfoQuestionIcon">?</div></div> -->
                <div style="width: 100%;">
                  <div class="popup-title">Edit Collection</div>
                  <div style="display: flex; flex-direction: column; padding: 1rem 0px 0px;">
                    <label style="margin-bottom: 4px;
                    font-size: 14px;
                    font-weight: 400;">Name <b style="color:red">*</b></label>
                    <input class="inputbox" type="text" style="width: 440px;" value="" (keyup)="keyDownValidator($event);" (keydown)="keyDownValidator($event);" maxlength="50" [(ngModel)]="collectionName"/>

                    <!-- <div class="input-section">
                      <div style="margin-bottom: 4px;
                      font-size: 14px;
                      font-weight: 400;">Description</div><div><textarea  (input)="getUpdatedDescription($event);" maxlength="100" style="width: 459px; height: 50px; resize: none;"   [value]="collection.description"></textarea> <p [style.color]="color">{{descriptionValidationMessage}}</p></div>
                     
                     
                  </div> -->
                  <div style="display: flex; flex-direction: column; padding: 1rem 0px 0px;">
                    <label style="margin-bottom: 4px;
                    font-size: 14px;
                    font-weight: 400;">Description</label><div><textarea  (input)="getUpdatedDescription($event);" maxlength="100" style="width: 459px; height: 50px; resize: none; border: 1px solid rgba(0, 0, 0, 0.3);"   [(ngModel)]="collection.description"></textarea> <p [style.color]="color">{{descriptionValidationMessage}}</p></div>
                   
                   
                </div>

                    <div *ngIf="inLineMsg" style="color: red; font-weight: 400; font-size: 12px;">{{message}}</div>
                  </div>
                  <div  class="renameBtn popup-action-section">
                    <button   class="docFavCancleBtn" (click)="CollectionRenamePopupShow = false; message=''">Cancel</button>
                    <button style="margin-left: 10px;" class="docFavSaveBtn" id="renameCollectionInServerId" (click)="renameCollectionInServer(collectionName)" [disabled]="!collectionName">Save</button>
                  </div>
                </div>
              </div>
                <!-- <div>Name - <input type="text" value="" [(ngModel)]="collectionName" /></div> -->
                <!-- <div><button (click)="renameCollectionInServer(collectionName)" formtarget="_blank">Save</button></div> -->
            </div>
        </div>
  </div>


 
  <div *ngIf="addToCollectionFlag">
    <div id="popup1" class="overlay" (click)="addToCollectionFlag=false"></div>
    <div class="popup">
        <div class="content">
            <div style="display: flex;">
                <!-- <div style="flex-basis: 10%;"><div class="oneDriveInfoQuestionIcon">?</div></div> -->
                <div style="width: 100%;">
                    <div class="popup-title">{{addOrRemoveFromCollectionsTitle}}</div>
                    <div class="popup-question">
                      <div>{{addOrRemoveFromCollectionsMsg1}}</div>
                      <div>{{addOrRemoveFromCollectionsMsg2}}</div>
                      <div style="    padding-top: 1rem;
                      font-weight: 700;">My Collections</div>
                    </div>
                    <div style="font-size: 100%; margin-bottom: 8px;" *ngFor="let collection of collectionslist">
                        <div style="cursor: pointer;">
                            <input type="checkbox" [id]="collection.id" [checked]="isDocPartOfCollection(collection)" [value]="collection.id" (change)="onCollectionCheckboxChange($event,collection)" />
                            <span>
                              <label style="cursor: pointer;" [for]="collection.id">{{collection.name}}</label>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="!collectionslist.length">
                           {{noCollectionsAvailableText}}
                    </div>
                    <div class="collectionsBtn">
                        <span (click)="addremoveCollections()" style="margin: 10px; text-decoration: none; cursor: pointer; color: teal; margin-left:0px;">+Create New Collection</span>
                    </div>

                    <div class="renameBtn popup-action-section">
                        <button  class="docFavCancleBtn" (click)="addToCollectionFlag = false;">Cancel</button>
                        <button style="margin-left: 10px;" id="addToCollectionFlagId" class="docFavSaveBtn" [disabled]="checkCollectionSize(collectionslist)" (click)="saveCollectionChanges('addToCollectionFlagId')">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<div id="popup1" *ngIf="CollectionDeletePopupMultipleShow" class="overlay" (click)="CollectionDeletePopupMultipleShow=false"></div>
<div class="popup" *ngIf="CollectionDeletePopupMultipleShow">
  <div class="content">
    <div style="display: flex;">
      <!-- <div style="flex-basis: 10%;">
         <div class="oneDriveInfoQuestionIcon">?</div>
      </div> -->
      <div style="width: 100%;">
         <div class="popup-title">Delete Collection</div>
         <div class="popup-question">Do you want to <span style="font-weight: bold;">delete the collections</span> named, "{{deleteCollectionNames}}" ?</div>
         <div  class="renameBtn popup-action-section">
           <button   class="docFavCancleBtn" (click)="CollectionDeletePopupMultipleShow=false;  this.deleteCollectionNames=''">Cancel</button>
           <button id="deleteCollectionsId" class="docFavSaveBtn" (click)="deleteMultiCollections()">Delete</button>
         </div>
      </div>
    </div>
  </div>
</div>

<div id="popup1" *ngIf="CollectionDeletePopupShow" class="overlay" (click)="CollectionDeletePopupShow=false"></div>
<div class="popup" *ngIf="CollectionDeletePopupShow">
  <div class="content">
    <div style="display: flex;">
      <!-- <div style="flex-basis: 10%;">
         <div class="oneDriveInfoQuestionIcon">?</div>
      </div> -->
      <div style="width: 100%;">
         <div class="popup-title">Delete Collection</div>
         <div class="popup-question">Do you want to <span style="font-weight: bold;">delete the collection</span> named, "{{collection.name}}" ?</div>
         <div  class="renameBtn popup-action-section">
           <button   class="docFavCancleBtn" (click)="CollectionDeletePopupShow=false">Cancel</button>
           <button id="deleteCollectionsId" class="docFavSaveBtn" (click)="deleteCollections()">Delete</button>
         </div>
      </div>
    </div>
  </div>
</div>






<div id="popup1" *ngIf="maxSelectionReached" class="overlay" (click)="maxSelectionReached=false"></div>
<div class="popup" *ngIf="maxSelectionReached">
  <div class="content">
    <div style="display: flex;">
      <!-- <div style="flex-basis: 10%;">
         <div class="oneDriveInfoQuestionIcon">?</div>
      </div> -->
      <div style="width: 100%;">
         <div class="popup-title">You may only select up to 10 at once.</div>
         <div class="popup-question">Maximum number of selections reached</div>
         <div  class="renameBtn popup-action-section">
           <button   class="docFavCancleBtn" (click)="maxSelectionReached=false">Close</button>
          
         </div>
      </div>
    </div>
  </div>
</div>



<div *ngIf="removeCollectionFlag">
  <div id="popup2" class="overlay" (click)="removeCollectionFlag=false"></div>
      <div class="popup">
          <div class="content">
            <div style="display: flex;">
              <!-- <div style="flex-basis: 10%;">
                 <div class="oneDriveInfoQuestionIcon">?</div>
              </div> -->
              <div style="width:100%">
                 <div class="popup-title">{{addOrRemoveFromCollectionsTitle}}</div>
                 <div class="popup-question">
                  <div>{{addOrRemoveFromCollectionsMsg1}}</div>
                      <div>{{addOrRemoveFromCollectionsMsg2}}</div>
                      <div style="    padding-top: 1rem;
                      font-weight: 700;">My Collections</div>
                 </div>
                 <div style="font-size: 100%; padding-bottom: 1%;" *ngFor="let collection of collectionslist">
                    <div style="cursor: pointer;"><input type="checkbox" [id]="collection.id" [checked]="isDocPartOfCollection(collection)" [value]="collection.id" (change)="onCollectionCheckboxChange($event,collection)" />
                       <span>
                       <label style="cursor: pointer;" [for]="collection.id">{{collection.name}}</label>
                       </span>
                    </div>
                 </div>
                 <div *ngIf="!collectionslist.length">
                  {{noCollectionsAvailableText}}
                 </div>
                 <div class="collectionsBtn">
                    <span (click)="addremoveCollections()" style="margin:10px; text-decoration: none; cursor: pointer; color: teal;">+Create New Collection</span>
                 </div>
                 <div  class="renameBtn popup-action-section">
                  <button   class="docFavCancleBtn" (click)="removeCollectionFlag = false">Cancel</button>
                  <button style="margin-left: 10px;" id="removeCollectionFlagId"  class="docFavSaveBtn" (click)="saveCollectionChanges('removeCollectionFlagId')">Save</button>
                 </div>
              </div>
           </div>
          </div>
      </div>
</div>



<div *ngIf="newnameFlag" style="z-index: 4;" id="popup5" class="overlay" (click)="newnameFlag=false; collectionName=''"></div>
<div class="popup" *ngIf="newnameFlag" style="box-shadow: 0px 3px 6px 0px #000000;">
  
  <div class="content">
    <div style="display: flex;">
      <!-- <div style="flex-basis: 10%;"><div class="oneDriveInfoQuestionIcon">?</div></div> -->
      <div style="width: 100%;">
        <div class="popup-title">Create Collection</div>
        <div class="popupBody" style="display: flex; flex-direction: column; padding: 1rem 0px 0px;">
          <label style="margin-bottom: 4px;
          font-size: 14px;
          font-weight: 400;">Name <b style="color:red">*</b></label>

          <input class="renameInput" (keyup)="keyDownValidator($event);" (keydown)="keyDownValidator($event);" maxlength="50"  type="text" (change)="newnameTextValue($event)" [(ngModel)]="collectionName">

          <label style="margin-bottom: 4px;
          font-size: 14px;
          font-weight: 400;">Description</label>
          <textarea  style="width: 459px; height: 50px; resize: none; border: 1px solid rgba(0, 0, 0, 0.3);"  type="text" (input)="onInputChange($event)" maxlength={{maxLength}}  [(ngModel)]="description"></textarea>
          <p [style.color]="color">{{descriptionValidationMessage}}</p>

          <div *ngIf="existnameFlag">
            <span style="color: red;margin-top:0.5rem">{{existnameMessage}}</span>
          </div>
        </div>
        <div class="renameBtn popup-action-section">
          <button   class="docFavCancleBtn" (click)="closeNewCollection(); collectionName=''; description='';descriptionValidationMessage='';">Cancel</button>
<!--           <button id="addCollectionsId" class="docFavSaveBtn" (click)="addCollections()">Save</button> -->
          <button id="addCollectionsId" class="docFavSaveBtn" (click)="addCollections(); collectionName=''" [disabled]="!collectionName">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div *ngIf="successFlag">
  <div id="popup4" class="overlay" (click)="closePopup()"></div>
      <div class="popupSuccess">
          <h5>Collections</h5>
          <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a>
          <div class="content">
           <span>{{message}}</span>
          </div>
          <div style="text-align:center;margin-top:1rem">
            <button style="color:teal !important"  class="docFavCancleBtn" (click)="closeAndRefresh()">Close</button>
          </div>
      </div>
</div> -->


<div *ngIf="successFlag">
  
  <div id="popup4" class="overlay" (click)="closePopup()"></div>
      <div class="popup">
          <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
          <div class="content">
              <div style="display: flex;">
                  <!-- <div style="flex-basis: 10%;"><div class="oneDriveInfoQuestionIcon">&check;</div></div> -->
                  <div style="width: 100%;">
                      <div class="popup-title">Collections</div>
                      <div class="popup-question"><div>{{message}}</div></div>
                      <div class="popup-action-section"><span></span><button class="docFavSaveBtn teal-button" style="width:7rem" (click)="closeMsgPopup()">Close</button></div>
                  </div>
              </div>
          </div>
      </div>
</div>


<div *ngIf="RenameSuccessFlag">
  
  <div id="popup4" class="overlay" (click)="closePopup()"></div>
      <div class="popup">
          <!-- <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a> -->
          <div class="content">
              <div style="display: flex;">
                  <!-- <div style="flex-basis: 10%;"><div class="oneDriveInfoQuestionIcon">&check;</div></div> -->
                  <div style="width: 100%;">
                      <div class="popup-title">Collections</div>
                      <div class="popup-question"><div>{{message}}</div></div>
                      <div class="popup-action-section"><span></span><button class="docFavSaveBtn teal-button" style="width:7rem" (click)="closeRenameMsgPopup()">Close</button></div>
                  </div>
              </div>
          </div>
      </div>
</div>










<!-- 
<div *ngIf="feature == 'addCollections' && collectionAddFlag" id="popup1" class="overlay" (click)="closePopup()">
       <div class="title popup">Save the document to the following collection(s)</div>
        <a class="close" href="javascript:void(0);" (click)="closePopup()">&times;</a>
         <div class="content" style="height:175px !important;overflow-y:scroll;width:95%">
          <div style="font-size: 100%; margin-top: 1rem; padding-bottom: 1%;" *ngFor="let collection of collectionslist">

          <div><input type="checkbox" [checked]="isDocPartOfCollection(collection)" [value]="collection.id" (change)="onCollectionCheckboxChange($event,collection)" />
            <span style="color: blue;text-decoration:underline">
              {{collection.name}}
            </span>
          </div>
         </div> 

       </div>
       <div class="collectionsBtn">
        <span (click)="addremoveCollections()" style="margin:10px">Add Collection</span>
        <span (click)="shareDocument()">Share Collection</span>
       </div>
       <div class="renameBtn">
        <button style="color:teal !important"  class="docFavCancleBtn" (click)="closeRenamePopup()">Cancel</button>
        <button class="docFavSaveBtn" (click)="saveCollectionChanges()">Save</button>
      </div> 
</div>
   -->
   <div *ngIf="" id="popup2" class="overlay" (click)="closePopup()">
    <div class="popup-content" style="padding: 20px; height: 100%; width: 100%;">
       <!-- <div>{{loadAllCollections()}}</div> -->
       <div class="title popup">My Collections</div>
        <!-- {{collection|json}} -->

         <div class="content" style="height:200px !important;overflow-y:scroll;width:95%">
          <div style="font-size: 100%; margin-top: 1rem; padding-right: 4%; padding-bottom: 1%;" *ngFor="let collection of collectionslist">

          <div><input type="checkbox" [checked]="isDocPartOfCollection(collection)" [value]="collection.id" (change)="onCollectionCheckboxChange($event,collection)" />
            <span style="color: blue;text-decoration:underline">
              {{collection.name}}
            </span>
            
           <span class="colSpan">
            <span (click)="rename(collection)" class="colSpace">Rename</span>
           <span (click)="delete(collection)" class="colSpace">Delete</span>
           <span (click)="share(collection)" class="colSpace">Share</span>
          </span>
          </div>
         </div> 

       </div>
   </div>
</div>



<div *ngIf="renameFlag == true" class="popup-overlay">
    <div class="popup-container">
      <h2>Rename Collection</h2>
      <!-- <p>This is the content of the popup.</p> -->
      <div class="popupBody">
        <label style="font-size: 14px;
        font-weight: 400;">Name</label>
        <input class="renameInput" type="text" (change)="renameTextValue($event)" >
      </div>
      <div class="renameBtn">
        <button   class="docFavCancleBtn" (click)="closeRenamePopup(); descriptionValidationMessage=''">Cancel</button>
        <button class="docFavSaveBtn" id="renameCollectionId" (click)="renameUpdate()">Rename</button>
      </div>    
        
      </div>
    </div>

  
    <div *ngIf="deleteFlag == true" class="popup-overlay">
      <div class="popup-container">
        <h2>Delete Confirmation</h2>
        <!-- <p>This is the content of the popup.</p> -->
        <div class="popupBody">
         <span>Do you want to delete the collection named, {{selecedNameList}}?</span>
        </div>
        <div class="renameBtn">
          <button   class="docFavCancleBtn" (click)="closeDeletePopup()">Cancel</button>
          <button class="docFavSaveBtn" id="deleteCollectionId" (click)="deleteCollection()">Delete</button>
        </div>    
          
        </div>
      </div>
      
  