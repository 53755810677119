<div class="container">
    <div class="title">
        <span>Data Sources</span>
    </div>
    <div class="card-container">
        <div *ngFor="let item of dataSources;" class="card">
            <div class="card-content">
                <h2>{{item.title}}</h2>
                <p>{{item.subtitle}}</p>
            </div>
        </div>
       
    </div>
</div>