import { Component, Input, NgZone, OnInit,inject } from '@angular/core'
import { Result } from '@coveo/headless'
import { CONFIG } from 'src/environments/environment.development'
import { OnedriveService } from '../services/onedrive.service'
import { SharedService } from '../shared.service'
import { AuthService } from '../auth.service'
import { CookieService } from 'ngx-cookie-service'
import {DOCUMENT} from '@angular/common';
import { Customeventlogging } from '../customevent-logging.service'
import { ThisReceiver } from '@angular/compiler'
import { Router } from '@angular/router'


@Component({
  selector: 'app-onedrive',
  templateUrl: './onedrive.component.html',
  styleUrls: ['./onedrive.component.css']
})
export class OnedriveComponent implements OnInit {
   onedriveInfoMessage:any= CONFIG.ONEDRIVE.INFO_MSG;
  downloadInProgress:any =CONFIG.MULTI_SELECT.OPERATION_IN_PROGRESS
  userText:any;
  oneDriveDownloadOperation:boolean = false;
  oneDriveRemovalOperation:boolean = false;
  selectedDocsCopy:any[] = [];
  @Input() result!: Result
  onedrive_icon_unfilled = CONFIG.ONEDRIVE.UNFILLED_ICON
  onedrive_icon_filled = CONFIG.ONEDRIVE.FILLED_ICON
  @Input() oneDriveDocs: any
  addToOneDriveFlag = false
  title = 'One drive'
  message1:any;
  document: any
  addToOneDriveMessage = false
  removeFromOneDriveFlag = false
  message: any
  @Input() selectedDocs: any
  private dom:any = inject(DOCUMENT);

  constructor (
    private onedriveService: OnedriveService,
    private ngZone: NgZone,
    private sharedService: SharedService,
    private authService: AuthService,
    private onedrive : OnedriveService,
    private cookieService: CookieService,
    private eventLoggingService : Customeventlogging,
    private router:Router
  ) {}
  ngOnInit (): void {}

  checkOneDriveStatus (result) {
    try{
      for (let doc of this.oneDriveDocs) {
        if (parseInt(doc) == parseInt(result.raw['contentsourceid'])) {
          return true
        }
      }
    }catch(err){

    }
    return false
  }
  
  oneDriveDownloadOperationPopup(){
    this.oneDriveDownloadOperation = false;
  }

  async addToOneDrive (result: any) {
    
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      var resp = await this.authService.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      // console.log("resp", resp);
           if(resp == "true"){
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    this.ngZone.run(async () => {
        // console.log("result", result);
        
        // console.log("result", result);

        if(localStorage.getItem("isOneDriveInProgress")){
            this.oneDriveDownloadOperation = true;
            return;
        } 
      var requestPayload = {
        "isRegistered": "",
        "id": this.authService.getUserId()
      }

      // console.log("requestPayload", requestPayload);

      // console.log("selected docs", this.selectedDocs);
      // console.log("selected docs length", this.selectedDocs.length);


      var checkIfUserAlreadyRegisteredResponse: any = await (await this.onedrive.checkIfUserAlreadyRegistered(requestPayload)).toPromise();

       // console.log("checkIfUserAlreadyRegisteredResponse", checkIfUserAlreadyRegisteredResponse)

      if (checkIfUserAlreadyRegisteredResponse.isRegistered == false && this.selectedDocs.length == 0) {
                     var source;
        localStorage.setItem('docId', result.raw['contentsourceid']);
        if (
          result.raw.source == 'Veeva Vault QualityDocs Source-1' ||
          result.raw.source == 'Veeva Vault QualityDocs Source-2' ||
          result.raw.source == 'Veeva Vault QualityDocs'
        ) {
          source = 'VVQD'
        }
        if (result.raw.source == 'Veeva Vault MedComms') {
          source = 'VVMC'
        }
        
        localStorage.setItem('source', source);
        localStorage.setItem("userId", this.authService.getUserId());

         // console.log("source", source, "userId", this.authService.getUserId(), "docId", result.raw['contentsourceid']);

        window.open(CONFIG.ONEDRIVE.REDIRECT_URL, "_blank");
        return;
      }

      if (checkIfUserAlreadyRegisteredResponse.isRegistered == false && this.selectedDocs.length > 0) {
//         var source;
// localStorage.setItem('docId', result.raw['contentsourceid']);
// if (
// result.raw.source == 'Veeva Vault QualityDocs Source-1' ||
// result.raw.source == 'Veeva Vault QualityDocs Source-2'
// ) {
// source = 'VVQD'
// }
// if (result.raw.source == 'Veeva Vault MedComms') {
// source = 'VVMC'
// }

// localStorage.setItem('source', source);
// localStorage.setItem("userId", this.authService.getUserId());

// // console.log("source", source, "userId", this.authService.getUserId(), "docId", result.raw['contentsourceid']);

localStorage.setItem('selectedDocs',JSON.stringify(this.selectedDocs));

window.open(CONFIG.ONEDRIVE.REDIRECT_URL_MULTIPLE, "_blank");
return;
}

this.title =CONFIG.ONEDRIVE.ONEDRIVE_DOWNLOAD_TITLE;

this.userText =  CONFIG.ONEDRIVE.SINGLE_DOCUMENT_DOWNLOAD_USERTEXT;
if (this.selectedDocs.length > 1) {
  this.userText =CONFIG.ONEDRIVE.MULTI_DOCUMENT_DOWNLOAD_USERTEXT
}
      this.addToOneDriveFlag = true
    })
  }

  sleep (ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }

  disableElement(id){

    if(this.dom.getElementById(id)){
   this.dom.getElementById(id).disabled = true;
   this.dom.getElementById(id).style.cursor="not-allowed";
   //document.getElementById(id).style.backgroundColor="grey";
   this.dom.getElementById(id).style.background="linear-gradient(to right, grey 50%, grey 50%)";
    }
    }

    enableElement(id){
     if(this.dom.getElementById(id)){
     this.dom.getElementById(id).disabled = false;
     //document.getElementById(id).style.cursor="pointer";
     //document.getElementById(id).style.background="linear-gradient(to right, #045F5F 50%,teal 20%)";
     //document.getElementById(id).style.background="linear-gradient(to right, grey 50%, grey 50%)";
     //document.getElementById(id).style.background= "";
     this.dom.getElementById(id).removeAttribute("style");
     }
   
        }



  async addToOneDriveServver () {
    if (this.selectedDocs.length > 1) {
      this.addMultipleDocsForOneDrive()
    } else {
      this.addToOneDriveSingle()
    }
  }

  async addToOneDriveSingle () {
    this.customEventAddOneDrive(this.result)
    this.disableElement("addToOneDriveId");
    this.message = CONFIG.ONEDRIVE.SINGLE_DOCUMENT_DOWNLOAD_PROGRESS_MESSAGE
    this.document = this.result;
    let content: any = ''
    var source
    // console.log('this.document.raw.source', this.document.raw.source)
    if (
      this.document.raw.source == 'Veeva Vault QualityDocs Source-1' ||
      this.document.raw.source == 'Veeva Vault QualityDocs Source-2'||
      this.document.raw.source == 'Veeva Vault QualityDocs'
    ) {
      source = 'VVQD'
    }
    if (this.document.raw.source == 'Veeva Vault MedComms') {
      source = 'VVMC'
    }
    // console.log('source', source)
    var docId = this.document.raw['contentsourceid'];
    // var userId = CONFIG.TEMP.USERID;
    var userId = this.authService.getUserId();
    var payload: any = {
      docId: docId,
      userId: userId
    }
    var uniqueId = ''
    var flag = true
    var status = false
    // var source =''
    do {
      if (uniqueId.length != 0) {
        payload['uniqueId'] = uniqueId

        await this.sleep(5000)
      }
      // console.log('payload', payload)
      
      if (source == 'VVMC') {
        content = await (
          await this.onedriveService.downloadVVMCDocumentToOneDrive(payload)
        ).toPromise()
      }
      if (source == 'VVQD') {
        content = await (
          await this.onedriveService.downloadVVQDocumentToOneDrive(payload)
        ).toPromise()
      }
      // console.log('oneDrive download Response', content)
      uniqueId = content.uniqueId

      if (content.msg == 'Upload Completed') {
        flag = false
        status = true
      }

      if (content.msg == 'Upload Failed') {
        flag = false
      }
    } while (flag)

    if (status) {
      // console.log('download complete')
      this.addToOneDriveFlag = false
      this.addToOneDriveMessage = true
     // this.message = 'Selected document(s) downloaded successfully.'
     this.message = CONFIG.ONEDRIVE.SINGLE_DOCUMENT_DOWNLOAD_SUCCESS;
      // this.sharedService.sendData(this.message)
    } else {
      // console.log('download is failed')
      this.addToOneDriveFlag = false
      this.addToOneDriveMessage = true
      // this.message = 'Download is failed.'
    //  this.message = CONFIG.ONEDRIVE.SINGLE_DOCUMENT_DOWNLOAD_FAILURE;
    if(content.errorStatusCode == "ERR-BIGSI"){
      // console.log("bigsi");
      this.message = CONFIG.ONEDRIVE.SINGLE_DOCUMENT_DOWNLOAD_FAILURE_BIGSIZE;
     
     
    }
    if(content.errorStatusCode == "ERR-SPCL"){
      // console.log("spcl");
      this.message = CONFIG.ONEDRIVE.SINGLE_DOCUMENT_DOWNLOAD_FAILURE_SPCL;
    }

if(content.errorStatusCode == "ERR-UNX"){
    // this.message = 'Download is failed.'
    // console.log("not any")
    this.message = CONFIG.ONEDRIVE.SINGLE_DOCUMENT_DOWNLOAD_FAILURE;
  }
    }
    this.enableElement("addToOneDriveId");
  }

  failedListDocs: any = []
 
  async addMultipleDocsForOneDrive () {
    this.ngZone.run(async () => {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'OneDrive-Multiple-Add',Details : this.selectedDocs},'OneDrive-Multi-Add');
    this.customEventAddOneDriveMulti(this.selectedDocs)
    this.selectedDocsCopy = JSON.parse(JSON.stringify(this.selectedDocs));
     localStorage.setItem("selectedDocsDisabilityCheck", JSON.stringify(this.selectedDocs));
    
     this.selectedDocs = [];
localStorage.setItem('isOneDriveInProgress', "1");
this.disableElement("addToOneDriveId");
// this.disableCheckList();
this.message = CONFIG.ONEDRIVE.MULTI_DOCUMENT_DOWNLOAD_PROGRESS_MESSAGE
//this.document = this.selectedDocs
this.document = this.selectedDocsCopy;
// console.log('Documents', this.document)
var uniqueId = ''
var flag = true
var status = false

// this.progress = true;
let source: any
// var userId = CONFIG.TEMP.USERID;
var userId = this.authService.getUserId();
var payloadItems: any = []
for (let i = 0; i < this.document.length; i++) {
  if(this.document[i].source != "GMSA News"){
payloadItems.push({
 docId: this.document[i].docId,
 userId: userId,
 source: this.document[i].source
})
}
}
// console.log('this.payloadItems', payloadItems);
// console.log('this.payloadItems length', payloadItems.length);
for await (const payload of payloadItems) {
flag = true
uniqueId = ''
await this.sleep(5000);
do {
 if (uniqueId.length != 0) {
    payload['uniqueId'] = uniqueId
 }
 await this.sleep(1000);
 // console.log('payload', payload);
 var oneDriveMultiplecontent: any
 if (payload.source == 'VVMC') {
   oneDriveMultiplecontent = await (
     await this.onedriveService.downloadVVMCDocumentToOneDrive(payload)
   ).toPromise()
 }
 if (payload.source == 'VVQD') {
   oneDriveMultiplecontent = await (
     await this.onedriveService.downloadVVQDocumentToOneDrive(payload)
   ).toPromise()
 }
 // console.log('oneDrive download Response', oneDriveMultiplecontent)
 uniqueId = oneDriveMultiplecontent.uniqueId

 if (oneDriveMultiplecontent.msg == 'Upload Completed') {
   flag = false
   // status = true
 }

 if (oneDriveMultiplecontent.msg == 'Upload Failed') {
   flag = false;

   

   let item = this.selectedDocsCopy.find(doc => doc.docId === payload.docId);

   let failedDoc = {
     id:payload.docId,
     name:item.name,
   }
   this.failedListDocs.push(failedDoc);
 }
} while (flag);

}

this.addToOneDriveMessage = true;
this.addToOneDriveFlag = false;


//  if(this.selectedDocs.length == this.failedListDocs.length){
//      this.message ="The selected document(s) failed to download to OneDrive";
//  }
if(this.failedListDocs.length == 0){
  // console.log("inside succesful message")
//  this.message ="The selected document(s) downloaded successfully to OneDrive.";
    this.message = CONFIG.ONEDRIVE.MULTI_DOCUMENT_DOWNLOAD_SUCCESS;
}
if(this.failedListDocs.length > 0){

// this.message = `The following  `
// this.message1 = " document(s) failed to download to OneDrive due to technical reasons.Please try again after sometime."
 this.message = CONFIG.ONEDRIVE.MULTI_DOCUMENT_DOWNLOAD_FAILURE_PART1;
 this.message1 = CONFIG.ONEDRIVE.MULTI_DOCUMENT_DOWNLOAD_FAILURE_PART2;
}
// console.log("this.failedListDocs---->"+JSON.stringify(this.failedListDocs));
this.enableElement("addToOneDriveId");
//this.selectedDocs = [];
this.selectedDocsCopy = [];
// console.log("this.addToOneDriveMessage",this.addToOneDriveMessage);
this.selectedDocs = [];
// console.log("==");
// console.log("I am here");
// this.cookieService.set('selectedDocuments',	"");
//  localStorage.setItem('selectedDocuments',	"");
localStorage.removeItem("selectedDocsDisabilityCheck");
localStorage.removeItem("isOneDriveInProgress");
localStorage.setItem("selectedDocuments","");

try{
  if(this.dom.getElementById("showSelected")){
    this.dom.getElementById("showSelected").style.display="none";
  }
}catch(err){

}
    })

}

  checkLength(arrayCustom:any){
    if(arrayCustom.length>0){
      return true;
    }else{
      return false;
    }
  }

  oneDriveRemovalOperationPopUp(){
    this.oneDriveRemovalOperation = false;
  }

  async removeFromOneDrive () {
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      var resp = await this.authService.getMaintenanceStatus({
        "checkForMaintainence":true
      });
           if(resp == "true"){
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    this.ngZone.run(() => {
      if(localStorage.getItem("isOneDriveInProgress")){
        this.oneDriveDownloadOperation = true;
        return;
       }


     // this.title = "Remove from OneDrive";
       this.title = CONFIG.ONEDRIVE.ONEDRIVE_REMOVE_TITLE;
       //this.userText = "Would you like to remove this document from your OneDrive?"
       this.userText = CONFIG.ONEDRIVE.SINGLE_DOCUMENT_REMOVE_USERTEXT;
        if(this.selectedDocs.length > 1){
         // this.userText = "Would you like to remove the selected document(s) from your OneDrive?"
         this.userText = CONFIG.ONEDRIVE.MULTI_DOCUMENT_REMOVE_USERTEXT
        }
      this.removeFromOneDriveFlag = true
    })
  }

  removeFromOneDriveServver () {
    this.failedListDocs = [];
    // this.message = "Document(s) removal continues in background, please feel free to close this popup";

    if(this.selectedDocs.length>0){
      this.removeMultipleDocsFromOneDrive();
    }else{
      this.removeSingleDocsFromOneDrive();
    }
   
  }
  

  async removeSingleDocsFromOneDrive(){
   // this.disableElement("removeFromOneDriveId");
  // this.message = "Document removal continues in background, please feel free to close this popup";
   this.message = CONFIG.ONEDRIVE.SINGLE_DOCUMENT_REMOVE_PROGRESS_MESSAGE;
    this.document = this.result;
    this.customEventRemoveOneDrive(this.result)

    // console.log('th', this.document)
    this.ngZone.run(async () => {
     
    
      var docId = this.document.raw['contentsourceid']
      // var userId = CONFIG.TEMP.USERID;
      var userId = this.authService.getUserId();
      var source
      if (this.document.raw['source'] == 'Veeva Vault MedComms') {
        source = 'VVMC'
      } else {
        source = 'VVQD'
      }

      let payload: any = {
        docId: docId,
        userId: userId,
        source: source
      }
      this.disableElement("removeFromOneDriveId");
      // console.log('payload', payload)
      let response: any = await this.onedriveService
        .deleteDocumentFromOneDrive(payload)
        .toPromise();
      // console.log('response', response);

      if (response.status == 200) {
        this.addToOneDriveMessage = true;
        this.removeFromOneDriveFlag = false;
        // this.message = "Document removed from OneDrive.";
        this.message = CONFIG.ONEDRIVE.SINGLE_DOCUMENT_REMOVE_SUCCESS;
        // const dataToSend = response
        // this.sharedService.sendData(dataToSend)
      } else {
        this.addToOneDriveMessage = true;
        this.removeFromOneDriveFlag = false;
        //  this.body=CONFIG.ONEDRIVE.REMOVE_SINGLE_DOCUMENT_FAILURE;
      }
      
      //  this.progress =false;
      //  this.closeBtn= true;
    })
    //this.enableElement("removeFromOneDriveId");
  

  }

  async removeMultipleDocsFromOneDrive () {
    // this.message = "Document(s) removal continues in background, please feel free to close this popup";

    this.ngZone.run(async () => {
    //  this.message = "Document(s) removal continues in background, please feel free to close this popup";
    this.message = CONFIG.ONEDRIVE.MULTI_DOCUMENT_REMOVE_PROGRESS_MESSAGE;
      this.selectedDocsCopy = JSON.parse(JSON.stringify(this.selectedDocs));
      localStorage.setItem("selectedDocsDisabilityCheck", JSON.stringify(this.selectedDocs));
      
     // this.selectedDocs = [];
localStorage.setItem('isOneDriveInProgress', "1");

       this.disableElement("removeFromOneDriveId");
      // console.log('selected docs in csn pop up', this.selectedDocs)
      this.customEventRemoveOneDriveMulti(this.selectedDocs)
      this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'OneDrive-Multiple-Remove',Details : this.selectedDocs},'OneDrive-Multi-Remove');
      var payloadItems = this.selectedDocsCopy.map(selectedDoc => {
        // selectedDoc.userId = CONFIG.TEMP.USERID
        selectedDoc.userId = this.authService.getUserId();
        return selectedDoc
      })
      // console.log('payloadItems', payloadItems)
      // console.log('removeMultipleDocsFromOneDrive Called in CSN pop up')
      for await (const payload of payloadItems) {
        // console.log('----docId---' + payload.docId + '-----started-----')
        let response:any = await this.onedriveService.deleteDocumentFromOneDrive(payload).toPromise();
        // console.log('response', response);
        // (response.status == 200 && response.message == "deleted successfully" )? failedListDocs.push(docId): "";
         (response.status == 504 && response.message != "error in deletion")? this.failedListDocs.push(payload.name): "";
        // console.log('----docId---' + payload.docId + '-----finished-----')
      }


      // if (response.status == 200) {
        this.addToOneDriveMessage = true;
        this.removeFromOneDriveFlag = false;
        // this.message = "Document(s) removed from OneDrive.";
        this.message =CONFIG.ONEDRIVE.MULTI_DOCUMENT_REMOVE_SUCCESS;
      // } else {
      //   this.addToOneDriveMessage = true;
      //   this.removeFromOneDriveFlag = false;
      // }

      this.selectedDocsCopy = [];
    this.selectedDocs = [];
    // console.log("==");
     // console.log("I am here");
    // this.cookieService.set('selectedDocuments',	"");
    //  localStorage.setItem('selectedDocuments',	"");
     localStorage.removeItem("selectedDocsDisabilityCheck");
     localStorage.removeItem("isOneDriveInProgress");
      // this.cookieService.set('selectedDocuments',	"");
      // console.log("here");
     // localStorage.setItem('selectedDocuments',	"");
      if(this.dom.getElementById("showSelected")){
        this.dom.getElementById("showSelected").style.display="none";
       }
      this.enableElement("removeFromOneDriveId");
      // this.sharedService.sendData("remove files");
    })
  }

  closePopup () {
    // this.cookieService.set('selectedDocuments',	"");
    //localStorage.setItem('selectedDocuments',	"");
    this.addToOneDriveFlag = false
    this.addToOneDriveMessage = false
    this.removeFromOneDriveFlag = false
  }

  closeAndRefresh () {
    //this.cookieService.set('selectedDocuments',	"");
    localStorage.setItem('selectedDocuments',	"");
    this.addToOneDriveFlag = false
    this.addToOneDriveMessage = false
    this.removeFromOneDriveFlag = false
    this.sharedService.sendData(this.message);
  }


  // custom events 

  customEventRemoveOneDrive(result)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'OneDrive-Remove-Actual', Title :result['Title'],'Source' :result['raw']['source']},'OneDrive-Remove-Actual');

  }

  customEventRemoveOneDriveMulti(result)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'OneDrive-Remove-Multi-Actual', Documents : result},'OneDrive-Remove-Multi-Actual');

  }

  customEventAddOneDrive(result)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'OneDrive-Add-Actual', Title :result['Title'],'Source' :result['raw']['source']},'OneDrive-Add-Actual');
  }

  customEventAddOneDriveMulti(result)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'OneDrive-Add-Multi-Actual','Documents' :result},'OneDrive-Add-Multi-Actual');
  }
}
