import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT } from 'src/environments/environment.development';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private httpClient:HttpClient) { }

  getNotificationsCount(requestPayload:any){

    return  this.httpClient.post(ENVIRONMENT.NOTIFICATIONS.GET_ALL_NOTIFICATIONS, requestPayload);

  }

  getNotifications(requestPayload:any){

    return  this.httpClient.post(ENVIRONMENT.NOTIFICATIONS.GET_ALL_NOTIFICATIONS, requestPayload);

  }

 async  getNotificationsBasedOnSearchId(requestPayload:any):Promise<Observable<any>>{
    
    return  this.httpClient.post(ENVIRONMENT.NOTIFICATIONS.GET_ALL_NOTIFICATIONS, requestPayload);

  }

  markNotificationAsViewed(requestPayload:any){
    return  this.httpClient.post(ENVIRONMENT.NOTIFICATIONS.GET_ALL_NOTIFICATIONS, requestPayload).toPromise();
  }

  removeNotificationUpdate(requestPayload:any){

    return  this.httpClient.post(ENVIRONMENT.NOTIFICATIONS.REMOVE_NOTIFICATIONS, requestPayload).toPromise();
  }

  async removeSavedSearchFromNotifications(requestPayload:any){
    return  await this.httpClient.post(ENVIRONMENT.NOTIFICATIONS.REMOVE_NOTIFICATIONS, requestPayload).toPromise();
  }

  async loadMoreNotifications(requestPayload:any){
    return  this.httpClient.post(ENVIRONMENT.NOTIFICATIONS.GET_ALL_NOTIFICATIONS, requestPayload).toPromise();
  }
}
