<div class="lightbox" id="lightbox-1" style="font-size: 14px;"></div>
<div id="searchbox" data-enable-history="true" data-excerpt-length="512" style="font-family: invention;">
    <app-header [userDeprovPage]="userDeprovPage"> </app-header>
    <div style="display: flex; flex-direction: row; justify-content: space-around;">
        <!-- <div class="breadcrum" style="display: flex;" (click)="backToResults()">
            <div style="line-height: 24px; margin-left: -23px;">Back to results</div>
        </div> -->
        <div style="flex-basis: 80%; text-align: center; line-height: 3rem; font-weight: 700;"><span style="font-size: 16px;">User Deprovisioning</span></div>
    </div>
    <div style="background: #ffffff; margin-left: 2.5rem; margin-right: 2.5rem; padding: 1rem 2rem 1rem 2rem; height: 70vh; overflow: auto;">
        <div style="font-size: 14px; font-family: Invention; font-weight: 400; display: flex; justify-content: center; align-items: baseline;">
            <div style="display: inline;">
                <label style="font-weight: 700; font-size: 14px;">Start Date: &nbsp;&nbsp;&nbsp;</label>
                <input style="height: 31px; padding-top: 2px;" placeholder="MMM-DD-YYYY" type="date" id="startDate" (change)="filterUsers();customEventDateStart()" />
            </div>
            <div style="display: inline; margin-left: 40px;">
                <label style="font-weight: 700; font-size: 14px;">End Date: &nbsp;&nbsp;&nbsp;</label>
                <input style="height: 31px; padding-top: 2px;" placeholder="MMM-DD-YYYY" type="date" id="endDate" (change)="filterUsers();customEventDate()" />
            </div>
            <div style="display: inline; margin-left: 10px; font-size: 14px; font-weight: 700;">
                <button id="exportToExcelBtn" (click)="exportUsersToExcel();customEventExport()">
                    Export to Excel
                </button>
            </div>
        </div>
        <div id="dataTable" style="padding-top: 1rem;">
            <table border="1" style="font: 14px Invention; border-collapse: collapse;">
                <thead style="font-weight: 700;" id="users_thead"></thead>
                <tbody style="font-weight: 400;" id="users_tbody"></tbody>
            </table>
        </div>
        <div>
            <div style="color: teal; text-align: center; font: 14px Invention; font-weight: 400;" id="loader" style="display: none;">
                {{loadingMessage}}
            </div>
        </div>
    </div>
    <app-footer></app-footer>
    <!-- <div
        class="footer"
        style="background: linear-gradient(90deg,#00857c,#00857c); text-align: center;"
      >
        <div class="info1">
          Copyright &copy; 2023 Merck & Co., Inc., Rahway, NJ, USA and its
          affiliates. All rights reserved.
        </div>
        <div class="info2"></div>
      </div> -->
</div>
